import { Component, OnInit } from '@angular/core';
import {
  ApiService,
  DataHolder,
  ProjectService,
  GlobalFormStateTrackerService,
  BaseSubscriptionComponent,
  pathTo,
  AppRoutingData,
  EmptyGuid,
} from '@app/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserNotificationService } from '@app/shared/services/user-notification/user-notification.service';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { debounceTime, map, skip, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProjectWithUserSettingsModel } from '@app/api';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';

@Component({
  selector: 'app-change-project',
  templateUrl: './change-project.component.html',
  styleUrls: ['./change-project.component.scss'],
})
export class ChangeProjectComponent implements Busy, OnInit {
  isBusy: boolean = true;
  currentProjectId: string;
  filter$ = new BehaviorSubject('');
  allProjects$ = new BehaviorSubject<ProjectWithUserSettingsModel[]>(this.fakeProjects());
  filteredProjects$ = combineLatest([this.allProjects$, this.filter$.pipe(debounceTime(300), startWith(''))]).pipe(
    map(([projects, filter]) =>
      !filter || this.isBusy // keep fake data on load
        ? projects
        : projects.filter(p => p.name.toLowerCase().includes(filter))
    )
  );

  constructor(
    private apiService: ApiService,
    private projectService: ProjectService,
    private router: Router,
    private globalFormStateTracker: GlobalFormStateTrackerService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<ChangeProjectComponent>
  ) {}

  async ngOnInit() {
    this.currentProjectId = this.projectService.projectId$.getValue();

    const projects = await using(new BusyScope(this), async () => {
      const projects = await firstValueFrom(this.apiService.getAvailableProjects());
      projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
      return projects;
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.companyData', e);
      this.close();
    });

    // let busy resolve for filter
    if (projects) this.allProjects$.next(projects);
  }

  async selectProject(project: ProjectWithUserSettingsModel) {
    if (this.isBusy) return;

    const isCurrentProjectSelected = project.id === this.currentProjectId;
    if (isCurrentProjectSelected) {
      this.close(project);
      return;
    }

    try {
      if (await this.globalFormStateTracker.canLeave()) {
        const split = this.router.url.split('?');
        let url = split[0].split('/').skip(3).join('/');
        const params = split.length > 1 ? `?${split[1]}` : '';

        if (!url || url == '') {
          url = AppRoutingData.dashboard.path;
        }

        await this.router.navigateByUrl(pathTo(project.id, url + params));
      }

      this.close(project);
    } catch (error) {
      this.userNotification.notify('companies.errorSwitchCompany', { error });
    }
  }

  close(project?: ProjectWithUserSettingsModel) {
    this.dialogRef.close(project);
  }

  private fakeProjects(): ProjectWithUserSettingsModel[] {
    return Array(3)
      .fill(1)
      .map(
        () =>
          new ProjectWithUserSettingsModel({
            id: EmptyGuid,
            name: 'Project Name',
          })
      );
  }
}
