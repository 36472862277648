import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export enum DashboardType {
  greeting = 'greeting',
  sectionHeader = 'sectionHeader',
  recentNotifications = 'recentNotifications',
  recentDocuments = 'recentDocuments',
  cards = 'cards',
  fileExchange = 'fileExchange',
  slider = 'slider',
  powerBI = 'powerBI',
  userList = 'userList',
  companyList = 'companyList',
}

export enum SideBarRenderType {
  image = 'image',
  app = 'app',
  none = 'none',
}

export enum TopBarRenderType {
  image = 'image',
  text = 'text',
}

export enum TopUserRenderType {
  default = 'default',
  logo = 'logo',
}

export interface PasswordSettings {
  requiredLength: number;
  requireNonAlphanumeric: boolean;
  requireDigit: boolean;
  requireLowercase: boolean;
  requireUppercase: boolean;
  requiredUniqueChars: number;
}

export interface Link {
  title: string;
  url: string;
}

export interface DashboardItem {
  type: DashboardType; //templateOutlet identifier
  settings?: any;
  cssClass?: string[];
  desk?: {
    width: number;
  };
  tablet?: {
    width: number;
  };
  phone?: {
    width: number;
  };
}

export interface DashboardRow {
  items: DashboardItem[];
  settings?: any;
  cssClass?: string[];
}

export interface Dashboard {
  rows: DashboardRow[];
}

export interface C4BIitem {
  token?: string;
  url?: string;
  reportId?: string;
  pageId?: string;
  visualId?: string;
  dashboardId?: string;
  tileId?: string;
  type: string;
}

export interface C4BISetting {
  id: string;
  token?: string;
  items: C4BIitem[];
  horizontalDir?: boolean;
  desk?: {
    itemWidth?: number;
    itemRatio?: number;
  };
  tablet?: {
    itemWidth?: number;
    itemRatio?: number;
  };
  phone?: {
    itemWidth?: number;
    itemRatio?: number;
  };
}

export interface C4Card {
  link?: string;
  header?: string;
  subHeader?: string;
  body?: string;
  iconClass?: string;
  target?: string;
  image?: string; //url
  linkIsRouterLink?: boolean;
  queryParams?: any;
  navIconClass?: string;
  lightText?: boolean;
  cssClass?: string[];
  fullLink?: boolean;
  style?: {};
}

export interface C4CardsSetting {
  id: string;
  cards: C4Card[];
  horizontalDir?: boolean;
  desk?: {
    itemWidth?: number;
    itemRatio?: number;
  };
  tablet?: {
    itemWidth?: number;
    itemRatio?: number;
  };
  phone?: {
    itemWidth?: number;
    itemRatio?: number;
  };
}

export interface Teams {
  websiteUrl: string;
  contentUrl: string;
  entityId: string;
  suggestedDisplayName: string;
}
export interface Admin {
  dashboard: Dashboard;
  cardsSettings?: C4CardsSetting[];
}

export interface DefaultRoleIds {
  everyone: string;
  intern: string;
}

export interface AppConfig {
  api: {
    url: string;
    taskPollingInMs: number;
    driveItemCachingInMs: number;
    upload: {
      useChunkedUploadThresholdInMb: number;
      maxFileSizeInMb: number;
      maxFilesAtOnce: number;
    };
    sockets: {
      leanSimulationLock: string;
    };
  };
  msal: {
    auth: {
      clientId: string;
      authority: string;
      redirectUri: string;
      postLogoutRedirectUri: string;
    };
    consentScopes: string[];
    unprotectedResources: [];
    protectedResourceMap: [string, string[]][] | Map<string, string[]>;
    extraQueryParameters: {};
  };
  offline: {
    syncIntervalInMinutes: number;
    healthCheckIntervalInSeconds: number;
  };
  publicDomain: string;
  fallbackSubdomain: string;
  snackbar: {
    dismissAfterMilliseconds: number;
  };
  tooltip: {
    showDelayInMs: number;
    hideDelayInMs: number;
    touchendHideDelayInMs: number;
  };
  style: {
    darkmax: string;
    lightmax: string;
    main: string;
    accent: string;
    background: string;
    backcontrast: string;
    text: string;
    mainText: string;
    accentText: string;
    warning: string;
    okay: string;
    font: string;
    textLink: string;
    backupFonts: string[];
  };
  language: string[];
  log: {
    debug: boolean;
  };
  frill: {
    key: string;
  };
  bryntum: {
    exportServer: string;
  };
  admin: Admin;
  title: string;
  cookiePrefix: string;
  cookieConsentConfig: NgcCookieConsentConfig;
  dashboard: Dashboard;
  topHeader?: TopBarRenderType;
  topUserHeader?: TopUserRenderType;
  sideHeader?: SideBarRenderType;
  cardsSettings?: C4CardsSetting[];
  biSettings?: C4BISetting[];
  teams: Teams;
  defaultRoleIds: DefaultRoleIds;
  importantLinks: Link[];
  supportMail: string;
  sentry: {
    replaysOnErrorSampleRate: number;
    replaysSessionSampleRate: number;
    tracesSampleRate: number;
  };
  timingVariables: {
    defectPrefillTtlInMinutes: number;
  };
}
