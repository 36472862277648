import { Injectable } from '@angular/core';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { LogService, ApiService, AppConfigService } from '@app/core';
import { DocumentDownloadData } from './document-download-interfaces';
import {
  DownloadDriveItemsRequest,
  DriveGenerateDownloadUrlResultModel,
  DriveItemIdentifierModel,
  ProblemDetails,
  SwaggerException,
} from '@app/api';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentDownloadService {
  constructor(
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    private log: LogService,
    private httpClient: HttpClient
  ) {}

  async downloadDocuments(documents: DocumentDownloadData[], showPreview: boolean = false, signedUrl: string = null) {
    //https://stackoverflow.com/questions/18451856/how-can-i-let-a-user-download-multiple-files-when-a-button-is-clicked
    //https://stackoverflow.com/questions/9047645/download-multiple-files-without-using-zip-file/9049986#9049986
    if (!documents) {
      return;
    }
    this.userNotification.notify('download.prepareDownload');

    if (documents.length === 1) {
      return await this.downloadDocument(documents[0], showPreview, signedUrl);
    }

    let urlResponse: DriveGenerateDownloadUrlResultModel;

    try {
      const requestData = this.getDownloadRequestData(documents);

      urlResponse = !!signedUrl
        ? await this.generateSignedDriveItemDownloadUrl(signedUrl, requestData)
        : await this.apiService.generateDriveItemDownloadUrl(requestData);

      this.handleMultiDownload(urlResponse);

      if (CapacitorUtils.isApp()) await this.userNotification.notify('documents.appMultiDownloadSuccess');
    } catch (e) {
      this.log.error(`Failed to download document ${urlResponse?.fileName}`, e);

      if (e instanceof ProblemDetails) {
        this.userNotification.notify(e.detail, { error: e });
      } else {
        this.userNotification.notify('documents.errorDownloadFailed', { error: e });
      }
    }
  }

  //only as a temporary solution. will be removed soon
  async downloadUrl(absoluteUrl: string, filename: string = 'Download') {
    //NOTE: keep same if structure like in "downloadDocuments"
    if (CapacitorUtils.isApp()) {
      await CapacitorUtils.urlFileDownload(absoluteUrl, this.httpClient, filename, true);
    } else if (this.isIosDevice() || this.isSafari()) {
      //works on iPad and iPhone
      await this.executeDownloadAsLocationChange(absoluteUrl);
    } else if (document.body.append) {
      await this.executeDownloadAsIFrame(absoluteUrl);
    } else {
      //IE bypass
      await this.executeDownloadAsLocationChange(absoluteUrl);
    }
  }

  private async downloadDocument(documentData: DocumentDownloadData, preview: boolean = false, signedUrl: string = null) {
    try {
      const requestData = this.getDownloadRequestData([documentData], preview);
      const urlResponse = !!signedUrl
        ? await this.generateSignedDriveItemDownloadUrl(signedUrl, requestData)
        : await this.apiService.generateDriveItemDownloadUrl(requestData);

      this.handleSingleDownload(urlResponse, preview);
    } catch (e) {
      this.log.error(`Failed to download document ${documentData.id}`, e);

      if (e instanceof ProblemDetails) {
        this.userNotification.notify(e.detail, { error: e });
      } else {
        let uiMsgKey = 'documents.errorDownloadFailed';
        if (SwaggerException.isSwaggerException(e)) {
          e = e as SwaggerException;
          if (e.status === 404) {
            uiMsgKey = 'documents.errorDownloadFailedFileMissing';
          }
        }
        this.userNotification.notify(uiMsgKey, { error: e });
      }
    }
  }

  private getDownloadRequestData(documents: DocumentDownloadData[], showPreview: boolean = false) {
    const downloadDriveItemsRequest = new DownloadDriveItemsRequest({
      items: documents.map(
        item =>
          new DriveItemIdentifierModel({
            driveItemId: item.id,
            resource: item.resource,
          })
      ),
      showPreview: showPreview,
    });

    return downloadDriveItemsRequest;
  }

  private async handleSingleDownload(urlResponse: DriveGenerateDownloadUrlResultModel, preview: boolean = false) {
    const url = urlResponse.url;
    const absoluteUrl = `${AppConfigService.settings.api.url}${url}`;

    //NOTE: keep same if structure like in "downloadDocuments"
    if (CapacitorUtils.isApp()) {
      await CapacitorUtils.urlFileDownload(absoluteUrl, this.httpClient, urlResponse.fileName, true);
    } else if (this.isIosDevice() || this.isSafari()) {
      //works on iPad and iPhone
      await this.executeDownloadAsLocationChange(absoluteUrl);
    } else if (preview) {
      await this.executeDownloadAsWindowOpen(absoluteUrl);
    } else if (document.body.append) {
      await this.executeDownloadAsIFrame(absoluteUrl);
    } else {
      //IE bypass
      await this.executeDownloadAsLocationChange(absoluteUrl);
    }
  }

  private async handleMultiDownload(urlResponse: DriveGenerateDownloadUrlResultModel) {
    const url = urlResponse.url;

    const absoluteUrl = `${AppConfigService.settings.api.url}${url}`;
    //NOTE: keep same if structure like in "downloadDocument"
    if (CapacitorUtils.isApp()) {
      await CapacitorUtils.urlFileDownload(absoluteUrl, this.httpClient, urlResponse.fileName, false);
    } else if (this.isIosDevice()) {
      //works on iPad, not on iPhone
      await this.executeDownloadAsLinkClick(absoluteUrl);
    } else if (document.body.append) {
      await this.executeDownloadAsIFrame(absoluteUrl);
    } else {
      //IE bypass
      await this.executeDownloadAsWindowOpen(absoluteUrl);
    }
  }

  private async executeDownloadAsLocationChange(url: string) {
    //works fine on all browser when we just have one link - use it for single file downloads
    window.location.href = url;
  }

  private async executeDownloadAsIFrame(url: string) {
    //works fine on chrome, FF, Edge, fails on IE (there it loads only one of the files when adding multiple)
    //seems to work on  android chrome
    //fails on iOS
    const element = document.createElement('iFrame');
    element.setAttribute('src', url);
    element.setAttribute('style', 'display: none');
    document.body.append(element); //IE would need appendChild
  }

  private async executeDownloadAsWindowOpen(url: string) {
    const newWin = window.open(url);

    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      this.userNotification.notify('documents.popUpBlocked');
    }
  }

  private async executeDownloadAsLinkClick(url: string) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.setAttribute('rel', 'noopener noreferrer');
    document.body.append(element);
    element.click();
  }

  private isIosDevice(): boolean {
    const isSafari = this.isSafari();
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return isSafari && iOS;
  }

  private isSafari(): boolean {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }

  private async generateSignedDriveItemDownloadUrl(signedUrl: string, requestData: DownloadDriveItemsRequest) {
    const req = new HttpRequest('POST', signedUrl, requestData, {
      reportProgress: true,
      responseType: 'json',
    });

    const response = await firstValueFrom(
      this.httpClient
        .request<DriveGenerateDownloadUrlResultModel>(req)
        .pipe(filter(response => response.type == HttpEventType.Response)) as Observable<
        HttpResponse<DriveGenerateDownloadUrlResultModel>
      >
    );

    return response.body;
  }

  async downloadAttachment(attachmentData: DocumentDownloadData) {
    try {
      await this.userNotification.notify('documents.downloadStart', {
        params: {
          file: attachmentData.fileName,
        },
      });

      const url = await this.apiService.generateAttachmentItemDownloadUrl(attachmentData.id);
      const absoluteUrl = `${AppConfigService.settings.api.url}${url}`;

      //NOTE: keep same if structure like in "downloadAttachment"
      if (CapacitorUtils.isApp()) {
        await CapacitorUtils.urlFileDownload(absoluteUrl, this.httpClient, attachmentData.fileName, false);
      } else if (this.isIosDevice()) {
        //works on iPad and iPhone
        await this.executeDownloadAsLocationChange(absoluteUrl);
      } else if (document.body.append) {
        await this.executeDownloadAsIFrame(absoluteUrl);
      } else {
        //IE bypass
        await this.executeDownloadAsLocationChange(absoluteUrl);
      }
    } catch (e) {
      this.log.error(`Failed to download document ${attachmentData.id}`, e);
      let uiMsgKey = 'documents.errorDownloadFailed';
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        if (e.status === 404) {
          uiMsgKey = 'documents.errorDownloadFailedFileMissing';
        }
      }
      this.userNotification.notify(uiMsgKey, { error: e });
    }
  }
}
