<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>
<button *ngIf="isProjectOffline; else onlineButton" mat-icon-button class="offline-state-button"
				[matTooltip]="'offline.actions' | translate" [matMenuTriggerFor]="offlineMenu">
	<span class="mdi mdi-cloud-off-outline" [iconBadge]="hasErrors ? 'exclamation-thick' : null"
				iconBadgePosition="before below" iconBadgeColor="warn"></span>
</button>

<ng-template #onlineButton>
	<button mat-icon-button class="offline-state-button" [matMenuTriggerFor]="onlineMenu">
		<span class="mdi mdi-cloud-outline"
					[iconBadge]="!isAutoSyncEnabled ? null : hasErrors && !isSyncing ? 'exclamation-thick' : 'autorenew'"
					[iconBadgeAnimation]="syncAnimation" iconBadgePosition="before below"
					[iconBadgeColor]="hasErrors ? 'warn' : null"></span>
	</button>
</ng-template>

<mat-menu #onlineMenu="matMenu">
	<button mat-menu-item (click)="goOffline()">{{ 'offline.menu.goOffline' | translate }}</button>
	<mat-divider></mat-divider>
	<button mat-menu-item (click)="toggleSync()">
		{{ 'offline.menu.' + (isAutoSyncEnabled ? 'disableSync' : 'enableSync') | translate }}
	</button>
	<button *ngIf="isAutoSyncEnabled" mat-menu-item (click)="triggerBackgroundSync()">
		{{ 'offline.sync.run' | translate }}
	</button>
	<button mat-menu-item *ngIf="hasErrors" (click)="showSyncError()">
		{{ 'offline.error.syncTitle' | translate }}
	</button>
	<ng-container *ngIf="lastSynced" [ngTemplateOutlet]="lastSyncedTemplate"></ng-container>
</mat-menu>

<mat-menu #offlineMenu="matMenu">
	<button mat-menu-item (click)="sync()" [disabled]="isAppOffline$ | async">
		{{ 'offline.menu.sync' | translate }}
	</button>
	<button mat-menu-item (click)="goOnline()" [disabled]="isAppOffline$ | async">
		{{ 'offline.menu.goOnline' | translate }}
	</button>
	<button mat-menu-item *ngIf="hasErrors" (click)="showSyncError()">
		{{ 'offline.error.syncTitle' | translate }}
	</button>
	<ng-container *ngIf="lastSynced">
		<mat-divider></mat-divider>
		<ng-container [ngTemplateOutlet]="lastSyncedTemplate"></ng-container>
	</ng-container>
</mat-menu>

<ng-template #lastSyncedTemplate>
	<button mat-menu-item disabled>
		{{ 'offline.menu.lastSynced' | translate:{ date: lastSynced | localizedDate:'relative' } }}
	</button>
</ng-template>