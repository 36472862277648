import { Component, Input, OnInit } from '@angular/core';
import { ProjectModel } from '@app/api';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input() cardStyle: CardStyle = CardStyle.card;
  @Input() logoSizeRem: number = 4.8;
  @Input() set project(value: ProjectModel) {
    this.id = value?.id;
    this.name = value?.name;
    this.route = '/projects/' + this.id;
    this.description = this.getProjectDescription(value);
  }

  id: string;
  name: string;
  route: string = '/projects';
  description: string = '';
  isDescriptionAddress: boolean;

  constructor() {}
  ngOnInit() {}

  private getProjectDescription(project: ProjectModel): string {
    let description = project?.description;

    if (!description?.length && project?.address) {
      this.isDescriptionAddress = true;
      const address = project.address;
      const city = `${address?.zipCode} ${address?.city}`.trim();
      const entries = [address?.street, city, address?.country];
      description = entries.filter(e => e).join(` ${this.cardStyle == CardStyle.list ? '' : '\n'}`);
    }

    return description;
  }
}

export enum CardStyle {
  card = 'card',
  list = 'list',
}
