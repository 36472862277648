import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, from, Observable, throwError } from 'rxjs';

import { AppConfigService, LogService, AuthenticationService, OfflineService } from '@app/core/services';
import { Router } from '@angular/router';
import { ApiCallAbortedException, GlobalsService } from '..';
import { catchError, filter, switchMap, take, finalize } from 'rxjs/operators';
import { UserNotificationService } from '@app/shared/services';
import { TeamsUrlParams } from '@app/C4CustomerPortal/msteams/msteams-enums';
import { ApiUrlService } from '../services/api/api-url.service';
import { CapacitorUtils } from '../utils/capacitor-utils';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private apiUrlService: ApiUrlService,
    private authenticationService: AuthenticationService,
    private userNotificationService: UserNotificationService,
    private globals: GlobalsService,
    private log: LogService,
    private offlineService: OfflineService,
    private router: Router
  ) {}

  intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isApiCall(originalRequest)) {
      //not an API call - do nothing
      return next.handle(originalRequest);
    }

    const request = originalRequest.clone({ url: this.apiUrlService.replaceApiFQDN(originalRequest.url) });

    if (this.log.debugEnabled()) {
      this.log.debug(`${request.method}: ${request.urlWithParams}`, request);
    }

    const accessTokenPromise = this.authenticationService.getCurrentAccessToken();
    return from(accessTokenPromise).pipe(
      switchMap(accessToken =>
        next.handle(this.addAuthenticationToken(request, accessToken)).pipe(
          catchError(error => {
            if (!this.isLogoutCall(request) && error instanceof HttpErrorResponse) {
              switch (error.status) {
                case 0:
                  if (CapacitorUtils.isApp()) this.offlineService.switchOfflineDueToError();
                  break;
                case 401:
                  if (!this.isAuthUserCall(originalRequest)) return this.handle401Error(request, next);
              }
            }

            throw error;
          })
        )
      )
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.authenticationService.refreshToken()).pipe(
        switchMap(result => {
          if (result) {
            this.refreshTokenSubject.next(result.accessToken);
            return next.handle(this.addAuthenticationToken(request, result.accessToken));
          }

          let queryParamMap = this.router.routerState.snapshot.root.queryParamMap;

          if (this.globals.isTeams && this.globals.currentTeamsContentUrl) {
            // custom logic for teams, if the refreshtoken fails, redirect to logout is insufficent as we need to automatically log in again
            const redirectURI = new URL(this.globals.currentTeamsContentUrl);
            const redirectParams = redirectURI.searchParams;

            redirectParams.set(TeamsUrlParams.TeamsRedirect, 'true');

            this.authenticationService.logout().then(() => {
              window.location.assign(redirectURI.toString());
            });
          } else if (this.globals.isOAuth || queryParamMap.has('OAuth')) {
            let queryParams = this.router.routerState.snapshot.root.queryParams;
            this.router.navigate(['logout'], { queryParams: { ...queryParams, OAuth: true } });
          } else {
            this.router.navigate(['logout']);
          }

          return throwError(new ApiCallAbortedException('Not authenticated'));
        }),
        catchError(error => {
          this.userNotificationService.notify('general.errorMsg.tokenExpired', { error: error });
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addAuthenticationToken(request, token));
        })
      );
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      setHeaders: {
        'ngsw-bypass': 'true',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  private isApiCall(request: HttpRequest<any>): boolean {
    if (!AppConfigService.settings) {
      return false;
    }
    return request.url.startsWith(AppConfigService.settings.api.url);
  }

  private isAuthUserCall(request: HttpRequest<any>): boolean {
    if (!this.isApiCall(request)) {
      return;
    }

    return request.url.toLowerCase().indexOf('/authuser') >= 0;
  }

  private isLogoutCall(request: HttpRequest<any>): boolean {
    if (!this.isApiCall(request)) {
      return;
    }

    return request.url.toLowerCase().indexOf('/session/logout') >= 0;
  }
}
