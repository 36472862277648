import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormComponent } from '@app/core/utils/form-component';
import { LegacyFloatLabelType as FloatLabelType } from '@angular/material/legacy-form-field';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface DateRange {
  from: Date | Moment;
  to: Date | Moment;
}

enum DateFilterPreset {
  today = 'today',
  yesterday = 'yesterday',
  lastSevenDays = 'lastSevenDays',
  lastThirtyDays = 'lastThirtyDays',
  lastYear = 'lastYear',
}

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangeComponent,
    },
  ],
})
export class DateRangeComponent extends FormComponent implements ControlValueAccessor, OnInit {
  @Input() floatLabel: FloatLabelType = 'auto';
  dateFilterPresets = Object.keys(DateFilterPreset);

  constructor(private formBuilder: FormBuilder) {
    super();

    this.form = this.formBuilder.group({
      from: [null],
      to: [null],
    });
  }

  ngOnInit(): void {
    this.subscribe(this.form.valueChanges, (dateRange: DateRange) => {
      this.onChange(dateRange);
    });
  }

  setDateFilter(filter: DateFilterPreset) {
    switch (filter) {
      case DateFilterPreset.today:
        this.form.setValue({
          from: moment().startOf('day'),
          to: moment().startOf('day'),
        });
        break;
      case DateFilterPreset.yesterday:
        this.form.setValue({
          from: moment().startOf('day').subtract(1, 'days'),
          to: moment().startOf('day').subtract(1, 'days'),
        });
        break;
      case DateFilterPreset.lastSevenDays:
        this.form.setValue({
          from: moment().startOf('day').subtract(7, 'days'),
          to: moment().startOf('day'),
        });
        break;
      case DateFilterPreset.lastThirtyDays:
        this.form.setValue({
          from: moment().startOf('day').subtract(30, 'days'),
          to: moment().startOf('day'),
        });
        break;
      case DateFilterPreset.lastYear:
        this.form.setValue({
          from: moment().startOf('day').subtract(1, 'year'),
          to: moment().startOf('day'),
        });
        break;
      default:
        this.form.setValue({
          from: null,
          to: null,
        });
        break;
    }
  }

  // ----- Reactive Forms Methods -----

  // isDisabled: boolean = false;
  private isTouched: boolean = false;
  onChange: Func<DateRange> = (_: DateRange) => {};
  private onTouched: EmptyCallback = () => {};

  writeValue(range: DateRange): void {
    this.form.setValue(range);
  }

  registerOnChange(fn: Func<DateRange>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: EmptyCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // this.isDisabled = isDisabled;
    if (isDisabled) this.form.disable();
    else this.form.enable();
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
