import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { AppConfig, DashboardType, SideBarRenderType, TopBarRenderType, TopUserRenderType } from './app-config';
import { ApiUrlService } from '../api/api-url.service';
import { ApiService } from '../api';
import { firstValueFrom } from 'rxjs';
import { LogService } from '../log/log.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  static settings: AppConfig;

  //this object is used to set undefined values in the returned settings object below
  //always extend when extending IAppConfig!
  static readonly defaultValues: AppConfig = {
    api: {
      url: '',
      taskPollingInMs: 2000,
      driveItemCachingInMs: 30000,
      upload: {
        useChunkedUploadThresholdInMb: 2,
        maxFileSizeInMb: 150,
        maxFilesAtOnce: 1000,
      },
      sockets: {
        leanSimulationLock: '/lean/lock',
      },
    },
    msal: {
      auth: {
        clientId: ``,
        authority: ``,
        redirectUri: '',
        postLogoutRedirectUri: '',
      },
      consentScopes: [],
      unprotectedResources: [],
      protectedResourceMap: [[, []]],
      extraQueryParameters: {},
    },
    offline: {
      syncIntervalInMinutes: 15,
      healthCheckIntervalInSeconds: 60,
    },
    publicDomain: '',
    fallbackSubdomain: '',
    snackbar: {
      dismissAfterMilliseconds: 5000,
    },
    tooltip: {
      showDelayInMs: 1000,
      hideDelayInMs: 0,
      touchendHideDelayInMs: 0,
    },
    style: {
      darkmax: '0, 0, 0',
      lightmax: '255, 255, 255',
      main: '255, 150, 0',
      accent: '93,108,193',
      background: '246, 246, 246',
      backcontrast: '255,255,255',
      text: '0, 0, 0',
      mainText: '255,255,255',
      accentText: '255,255,255',
      warning: '240, 0, 0',
      okay: '65, 188, 0',
      textLink: '120,180,120',
      font: 'Roboto',
      backupFonts: ['Helvetica Neue', 'sans-serif'],
    },
    language: ['de-DE', 'en-US'],
    log: {
      debug: false,
    },
    frill: {
      key: 'key',
    },
    bryntum: {
      exportServer: 'url',
    },
    admin: {
      dashboard: {
        rows: [
          {
            items: [
              {
                type: DashboardType.sectionHeader,
                settings: {
                  title: 'dashboard.title',
                },
                cssClass: ['slim'],
              },
            ],
            settings: {},
            cssClass: ['block'],
          },
          {
            items: [
              {
                type: DashboardType.companyList,
                settings: {
                  head: {
                    title: 'dashboard.companyList.title',
                    subTitle: 'dashboard.companyList.subTitle',
                    link: '/companies',
                    icon: 'mdi mdi-notebook',
                  },
                  desk: {
                    columns: 2,
                    count: 8,
                  },
                  tablet: {
                    columns: 2,
                    count: 8,
                  },
                  phone: {
                    columns: 1,
                    count: 5,
                  },
                  staticContent: false,
                  count: 8,
                },
                desk: {
                  width: 6,
                },
                tablet: {
                  width: 10,
                },
                phone: {
                  width: 10,
                },
              },
              {
                type: DashboardType.companyList,
                settings: {
                  id: 'NotificationAndCompanyCreateLinks',
                },
                desk: {
                  width: 4,
                },
                tablet: {
                  width: 10,
                },
                phone: {
                  width: 10,
                },
              },
            ],
            settings: {},
            cssClass: ['flex'],
          },
          {
            items: [
              {
                type: DashboardType.userList,
                settings: {
                  head: {
                    title: 'dashboard.userList.title',
                    subTitle: 'dashboard.userList.subTitle',
                    link: '/users',
                    icon: 'mdi mdi-account-group',
                  },
                  desk: {
                    columns: 2,
                    count: 8,
                  },
                  tablet: {
                    columns: 2,
                    count: 8,
                  },
                  phone: {
                    columns: 1,
                    count: 5,
                  },
                  staticContent: false,
                  count: 8,
                },
                desk: {
                  width: 6,
                },
                tablet: {
                  width: 10,
                },
                phone: {
                  width: 10,
                },
              },
              {
                type: DashboardType.cards,
                settings: {
                  id: 'UserCreateLink',
                },
                desk: {
                  width: 4,
                },
                tablet: {
                  width: 10,
                },
                phone: {
                  width: 10,
                },
              },
            ],
            settings: {},
            cssClass: ['flex'],
          },
        ],
      },
      cardsSettings: [
        {
          id: 'NotificationAndCompanyCreateLinks',
          horizontalDir: true,
          desk: {
            itemWidth: 10,
          },
          tablet: {
            itemWidth: 5,
          },
          phone: {
            itemWidth: 10,
          },
          cards: [
            {
              body: 'dashboard.notificationAndCompanyCreateLinks1.body',
              link: '/dashboard',
              linkIsRouterLink: true,
              header: 'dashboard.notificationAndCompanyCreateLinks1.header',
              subHeader: 'dashboard.notificationAndCompanyCreateLinks1.subHeader',
              iconClass: 'dashboard.notificationAndCompanyCreateLinks1.iconClass',
              navIconClass: 'links.navIconClass',
              queryParams: {
                editNotification: true,
              },
            },
            {
              body: 'dashboard.notificationAndCompanyCreateLinks2.body',
              link: '/projects',
              linkIsRouterLink: true,
              header: 'dashboard.notificationAndCompanyCreateLinks2.header',
              subHeader: 'dashboard.notificationAndCompanyCreateLinks2.subHeader',
              iconClass: 'dashboard.notificationAndCompanyCreateLinks2.iconClass',
              navIconClass: 'links.navIconClass',
              queryParams: {
                create: true,
              },
            },
          ],
        },
        {
          id: 'UserCreateLink',
          horizontalDir: true,
          desk: {
            itemWidth: 10,
          },
          tablet: {
            itemWidth: 5,
          },
          phone: {
            itemWidth: 10,
          },
          cards: [
            {
              body: 'dashboard.userCreateLink.body',
              link: '/users',
              linkIsRouterLink: true,
              header: 'dashboard.userCreateLink.header',
              subHeader: 'dashboard.userCreateLink.subHeader',
              iconClass: 'dashboard.userCreateLink.iconClass',
              navIconClass: 'links.navIconClass',
              queryParams: {
                create: true,
              },
            },
          ],
        },
      ],
    },
    topHeader: TopBarRenderType.image,
    topUserHeader: TopUserRenderType.default,
    sideHeader: SideBarRenderType.app,
    title: 'c4.TeamsPortal',
    cookiePrefix: '',
    cookieConsentConfig: {
      enabled: false,
      cookie: {
        domain: '',
      },
    },
    dashboard: {
      rows: [
        {
          items: [
            {
              type: DashboardType.sectionHeader,
              cssClass: [],
              settings: { title: 'Dashboard' },
            },
          ],
          cssClass: ['block'],
          settings: {},
        },
      ],
    },
    cardsSettings: [],
    biSettings: [],
    teams: {
      contentUrl: '',
      websiteUrl: '',
      entityId: '',
      suggestedDisplayName: '',
    },
    defaultRoleIds: {
      everyone: '',
      intern: '',
    },
    importantLinks: [],
    supportMail: '',
    sentry: {
      replaysOnErrorSampleRate: 0,
      replaysSessionSampleRate: 0,
      tracesSampleRate: 0,
    },
    timingVariables: {
      defectPrefillTtlInMinutes: 90,
    },
  };

  constructor(private httpBackend: HttpBackend, private log: LogService) {}

  static updateUrls(apiUrlService: ApiUrlService) {
    const settings = AppConfigService.settings;

    settings.api.sockets.leanSimulationLock = apiUrlService.replaceApiFQDN(settings.api.sockets.leanSimulationLock);
    settings.msal.auth.redirectUri = apiUrlService.replaceApiFQDN(settings.msal.auth.redirectUri);
    settings.msal.auth.postLogoutRedirectUri = apiUrlService.replaceApiFQDN(settings.msal.auth.postLogoutRedirectUri);
    settings.cookieConsentConfig.cookie.domain = apiUrlService.replaceApiFQDN(settings.cookieConsentConfig.cookie.domain);
  }

  async load(configPath: string) {
    //inspired by https://devblogs.microsoft.com/premier-developer/angular-how-to-editable-config-files/
    try {
      const http = new HttpClient(this.httpBackend); // httpBackend handler will not get intercepted
      const appConfig = await firstValueFrom(http.get<AppConfig>(configPath));
      AppConfigService.settings = appConfig;
      //inject default values if not set
      this.setDefaultValues(AppConfigService.settings, AppConfigService.defaultValues);
    } catch (e) {
      this.log.error(`Could not load configuration file '${configPath}': ${JSON.stringify(e)}`);
    }
  }

  async loadCustomization(apiService: ApiService) {
    try {
      const customization = await apiService.getCustomization();

      AppConfigService.settings.style.main = customization.mainColor;
      AppConfigService.settings.style.accent = customization.accentColor;
      AppConfigService.settings.style.background = customization.backgroundColor;
      AppConfigService.settings.style.backcontrast = customization.backcontrastColor;
      AppConfigService.settings.style.text = customization.textColor;
      AppConfigService.settings.style.mainText = customization.mainTextColor;
      AppConfigService.settings.style.accentText = customization.accentTextColor;
      AppConfigService.settings.style.font = customization.font;
    } catch {}
  }

  private setDefaultValues(target: any, source: any) {
    for (const key of Reflect.ownKeys(source)) {
      const sourceChild = source[key];
      const sourceChildType = typeof sourceChild;
      const isArray = Array.isArray(sourceChild);

      if (!this.isDefined(target[key], sourceChildType, isArray)) {
        //property missing: set
        target[key] = sourceChild;
        continue;
      }

      if (sourceChildType === 'object') {
        //traverse child object
        if (!isArray) {
          this.setDefaultValues(target[key], sourceChild);
        }
      } else if (sourceChildType === 'string') {
        //overwrite if only empty string is set
        if ((target[key] as string).length <= 0) {
          target[key] = sourceChild;
        }
      }
    }
  }

  private isDefined(value: any, expectedType: string, isArray: boolean): boolean {
    if (value === null || value === undefined) {
      //missing -> not defined
      return false;
    }
    if (expectedType !== typeof value) {
      //wrong type -> not defined
      return false;
    }

    if (isArray !== Array.isArray(value)) {
      //array missmatch -> not defined
      return false;
    }

    return true;
  }
}
