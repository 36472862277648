import { VERSION } from './version';

export const environment = {
  production: true,
  version: `${VERSION.version}-${VERSION.hash}`,
  name: 'BUILD 365',
  indexdbversion: '1.0', // please change it in the other env file too
  settingsFile: `assets/customization/settings.json?version=${VERSION.hash}`,
  languagesFolder: 'assets/languages/',
  languagesCustomizationFolder: 'assets/customization/languages/',
  languagesSuffix: `.json?version=${VERSION.hash}`,
  sentryUrl: 'https://4dc144bb2c94f06f101839271a6aa6c5@o4507072579895296.ingest.de.sentry.io/4507259653980240',
  sentryEnvironment: 'production',
};
