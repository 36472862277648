import { ConfigType, SyncClient } from '@app/api';
import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { IEntityWithContent, IPSCraft, IPSDefectType, IPSReason, IPSArea, IPSFloor, IPSUserWithProjectUserModel } from '.';
import {
  IPSComment,
  IPSCommentWithUser,
  IPSProjectOrganization,
  IPSProjectOrganizationCraft,
  IPSDefect,
  IPSDefectComment,
  IPSDriveItem,
  IPSOrganization,
  IPSProject,
  IPSSchema,
  IPSSyncError,
  IPSProjectPrivilege,
  IPSRegion,
  IPSResource,
  IPSRoom,
  IPSZoneGroup,
  IPSZone,
  IPSCommentWithUserAndDefectId,
} from './definitions';
import { migrateIfRequired, MigrationStatus, SQLiteTables } from './sqlite/utils';
import { Builders, DBFilter, SQLStatements } from './SQLStatements';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';

export interface IQueryWrapper {
  // --------------------------------------------------
  // Core
  // --------------------------------------------------

  resetProject(projectId: string): Promise<void>;

  getStatements(): Promise<SQLStatements>; // YES, YES i know should be an interface

  getLastModified(table: SQLiteTables, condition?: QueryCondition): Promise<Date>;
  getObjectById<T>(table: SQLiteTables, query: string | { [key: string]: string | number }): Promise<T>;
  getObjectsByIds<T>(table: SQLiteTables, ids: string[]): Promise<T[]>;

  // --------------------------------------------------
  // Metadata
  // --------------------------------------------------

  getMetadata(name: string): Promise<string>;

  getResourceIdentifiers(projectId: string): Promise<IPSResource[]>;

  getProjects(): Promise<IPSProject[]>;
  getProjectById(id: string): Promise<IPSProject>;
  getProjectLastSynced(id: string): Promise<Date>;
  setProjectsOnline(offline: boolean): Promise<void>;
  setProjectOfflineStatus(id: string, offline: boolean): Promise<void>;

  getSyncErrors(projectId: string): Promise<IPSSyncError[]>;

  // --------------------------------------------------
  // Entities
  // --------------------------------------------------

  getAreas(projectId: string): Promise<IPSArea[]>;
  getAreaWhereId(areaId: string): Promise<IPSArea>;

  getCommentById(id: string): Promise<IPSComment>;
  getCommentsWhereDefect(defectId: string): Promise<IPSComment[]>;
  getCommentsWithUserWhereDefect(defectId: string): Promise<IPSCommentWithUser[]>;
  getCommentsWithUserWhereDefects(defectId: string[]): Promise<IPSCommentWithUserAndDefectId[]>;
  getCommentsWhereModeAndDefect(mode: MigrationStatus | MigrationStatus[], projectId?: string): Promise<IPSComment[]>;

  getProjectOrganizations(projectId: string): Promise<IPSProjectOrganization[]>;

  getProjectOrganizationCrafts(projectOrganizationId: string): Promise<IPSProjectOrganizationCraft[]>;

  getCrafts(projectId?: string): Promise<IPSCraft[]>;

  getDefectById(id: string): Promise<IPSDefect>;
  getDefects(projectId: string): Promise<IPSDefect[]>;
  getDefectsWhereModified(projectId?: string): Promise<IPSDefect[]>;

  getDefectsWhereMode(mode: MigrationStatus | MigrationStatus[], projectId?: string): Promise<IPSDefect[]>;
  getDefectCommentsWhereMode(mode: MigrationStatus | MigrationStatus[], projectId?: string): Promise<IPSDefectComment[]>;

  getDefectTypes(projectId: string): Promise<IPSDefectType[]>;

  getDefectReasons(projectId: string): Promise<IPSReason[]>;

  getDriveItems(projectId?: string): Promise<IPSDriveItem[]>;
  getDriveItemsWhereResourceAndId(projectId: string, resource: string, id: string): Promise<IPSDriveItem[]>;
  getDriveItemsWhereMode(mode: MigrationStatus, projectId?: string): Promise<IPSDriveItem[]>;
  getDriveItemsWhereModeIsInsertAndPath(projectId: string, path: string, resource: string): Promise<IPSDriveItem[]>;
  getDriveItemsWhereModeIsInsertAndResource(projectId: string, resource: string): Promise<IPSDriveItem[]>;

  getRegionsWhereArea(areaId: string): Promise<IPSRegion[]>;

  getFloorWhereId(floorId: string): Promise<IPSFloor>;
  getFloorsWhereArea(areaId: string): Promise<IPSFloor[]>;

  getProjectUsersWithUser(projectId: string): Promise<IPSUserWithProjectUserModel[]>;

  getOrganizations(projectId: string): Promise<IPSOrganization[]>;

  getRoomsWhereId(roomId: string): Promise<IPSRoom[]>;
  getRoomsWhereFloor(floorId: string): Promise<IPSRoom[]>;

  getZoneGroups(projectId: string): Promise<IPSZoneGroup[]>;
  getZones(projectId: string): Promise<IPSZone[]>;
  getZonesWhereZoneGroup(zoneGroupId: string): Promise<IPSZone[]>;

  getSchemaWhereType(projectId: string, type: ConfigType): Promise<IPSSchema>;

  getUserPrivilegesForProject(projectId: string): Promise<IPSProjectPrivilege[]>;

  dump(filter: DBFilter | false): Promise<{ [key: string]: any }>;
}

export enum QueryCondition {
  onlyOriginal = 'WHERE mode IN ("' + MigrationStatus.EXISTS + '", "' + MigrationStatus.UPDATE + '")',
  modified = 'WHERE mode IN ("' + MigrationStatus.UPDATE + '", "' + MigrationStatus.INSERT + '")',
}

export class SQLiteQueryWrapper implements IQueryWrapper {
  private corePromise: Promise<SQLiteConnection>;
  private connPromise: Promise<SQLiteDBConnection>;

  constructor(syncClient: SyncClient) {
    if (!CapacitorUtils.isApp()) {
      return;
    }

    this.corePromise = new Promise(async (resolve, reject) => {
      try {
        const core = new SQLiteConnection(CapacitorSQLite);

        try {
          await core.closeConnection('main', false);

          console.warn('Page reaload detected');
        } catch {}

        resolve(core);
      } catch ($err) {
        console.error('Database is not working:', $err);

        reject($err);
      }
    });

    this.openConnection(true, syncClient);
  }

  private openConnection(migrate: boolean, syncClient: SyncClient) {
    this.connPromise = new Promise(async (resolve, reject) => {
      try {
        const core = await this.corePromise;

        const conn = await core.createConnection('main', false, '', 0, false);

        await conn.open();

        if (migrate) {
          await migrateIfRequired(conn, this, syncClient);
        }

        resolve(conn);
      } catch ($err) {
        console.error('Database is not working:', $err);

        reject($err);
      }
    });
  }

  async getSyncErrors(projectId: string) {
    return await this.query(`SELECT * FROM ${SQLiteTables.SyncErrors} WHERE project = ?`, projectId);
  }

  getConnection(): Promise<SQLiteDBConnection> {
    return !CapacitorUtils.isApp() ? Promise.reject('Platform is not native') : this.connPromise;
  }

  // --------------------------------------------------
  // Core
  // --------------------------------------------------

  async getStatements(): Promise<SQLStatements> {
    const conn = await this.getConnection();
    return new SQLStatements(conn);
  }

  async dump(filter: DBFilter | false) {
    const tables = await this.query(`select * from sqlite_master where type = 'table'`);
    const result = {};

    for (const table of tables) {
      if (filter) {
        const columns = await this.query(`PRAGMA table_info('${table.name}')`);

        if ('project' in filter) {
          if (!columns.some(x => x.name == 'project')) {
            continue;
          }
        }

        if ('mode' in filter) {
          if (!columns.some(x => x.name == 'mode')) {
            continue;
          }
        }

        const args = [];
        const condition = Builders.Filter(filter, args);

        const rows = await this.query(`SELECT * FROM [${table.name}] WHERE ${condition}`, ...args);

        if (rows.length > 0) {
          result[table.name] = rows;
        }
      } else {
        const rows = await this.query(`SELECT * FROM [${table.name}]`);

        if (rows.length > 0) {
          result[table.name] = rows;
        }
      }
    }

    return result;
  }

  async query(statement: string, ...args: any[]): Promise<any[]> {
    const conn = await this.getConnection();
    try {
      // connection already opened on ctor
      // await conn.open();

      const result = await conn.query(statement, args);

      return result.values;
    } finally {
      // await conn.close();
    }
  }

  // --------------------------------------------------
  // Generic
  // --------------------------------------------------

  async getLastModified(table: SQLiteTables, condition?: QueryCondition) {
    const parts = ['SELECT MAX(modifiedOn) AS modifiedOn', `FROM ${table}`];

    if (condition) {
      parts.push(condition.toString());
    }

    const [row] = await this.query(parts.join(' '));

    if (row) {
      return new Date(row.modifiedOn);
    }

    return null;
  }

  async getObjectsByIds<T>(table: SQLiteTables, ids: string[]): Promise<T[]> {
    const distinctIds = ids?.filter(i => i).distinct() ?? [];
    if (!distinctIds.length) return [];

    const parameters = [];
    const conditions = [];

    const placeholders = distinctIds.map(() => '?').join(',');

    conditions.push(`id IN (${placeholders})`);
    parameters.push(...distinctIds);

    const result: IEntityWithContent[] = await this.query(
      'SELECT * FROM ' + table + ' WHERE ' + conditions.join(' AND '),
      ...parameters
    );

    if (!result.length) {
      throw new Error(`No Entities found searching for [${distinctIds.join(', ')}] in table ${table}`);
    }

    const models: T[] = [];
    for (const r of result) {
      const model: T = JSON.parse(r.json);
      models.push(model);
    }

    return models;
  }

  async getObjectById<T>(table: SQLiteTables, query: string | { [key: string]: string | number }): Promise<T> {
    if (!query) {
      return null;
    }

    const parameters = [];
    const conditions = [];
    if (typeof query == 'string') {
      conditions.push('id = ?');
      parameters.push(query);
    } else {
      for (const k of Object.keys(query)) {
        conditions.push(`${k} = ?`);
        parameters.push(query[k]);
      }
    }

    const [result]: IEntityWithContent[] = await this.query(
      'SELECT * FROM ' + table + ' WHERE ' + conditions.join(' AND '),
      ...parameters
    );

    if (!result) {
      throw new Error(`Entity with ${query} not found in table ${table}`);
    }

    const model: T = JSON.parse(result.json);

    return model;
  }

  // --------------------------------------------------
  // Metadata
  // --------------------------------------------------

  async getMetadata(name: string) {
    const [firstOrDefault] = await this.query('SELECT * FROM metadata WHERE name = ?', name);
    return firstOrDefault?.data;
  }

  // --------------------------------------------------
  // Resources
  // --------------------------------------------------

  async getResourceIdentifiers(projectId: string) {
    const resourceIdentifiers = await this.query('SELECT * FROM resources WHERE project = ?', projectId);
    return resourceIdentifiers;
  }

  // --------------------------------------------------
  // Projects
  // --------------------------------------------------

  async getProjects() {
    return await this.query(`SELECT * FROM ${SQLiteTables.Projects}`);
  }

  async getProjectById(id: string) {
    const [project] = await this.query(`SELECT * FROM ${SQLiteTables.Projects} WHERE id = ?`, id);
    return project;
  }

  async getProjectLastSynced(id: string) {
    const project = await this.getProjectById(id);
    return project.sync_datetime;
  }

  async setProjectOfflineStatus(id: string, offline: boolean) {
    const flag = offline ? 1 : 0;

    await this.query(`UPDATE ${SQLiteTables.Projects} SET offline = ? WHERE id = ?`, flag, id);
  }

  async setProjectsOnline(): Promise<void> {
    await this.query(`UPDATE ${SQLiteTables.Projects} SET offline = 0`);
  }

  async resetProject(projectId: string) {
    await this.query(`DELETE FROM ${SQLiteTables.Projects} where id = ?`, projectId);

    await this.query(`DELETE FROM ${SQLiteTables.DefectComments} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.DefectReasons} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.DefectTypes} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.Defects} where project = ?`, projectId);

    await this.query(`DELETE FROM ${SQLiteTables.ProjectOrganizationCrafts} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.ProjectOrganizations} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.ProjectPrivileges} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.Organizations} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.ProjectUsers} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.SyncErrors} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.DriveItems} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.Resources} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.Comments} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.Regions} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.Crafts} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.Areas} where project = ?`, projectId);
    await this.query(`DELETE FROM ${SQLiteTables.Rooms} where project = ?`, projectId);
  }

  async getUserPrivilegesForProject(projectId: string): Promise<IPSProjectPrivilege[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.ProjectPrivileges} WHERE project = ?`, projectId);
  }

  // --------------------------------------------------
  // Areas
  // --------------------------------------------------

  async getAreas(projectId: string): Promise<IPSArea[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Areas} WHERE project = ?`, projectId);
  }

  async getAreaWhereId(areaId: string): Promise<IPSArea> {
    const query = await this.query(`SELECT * FROM ${SQLiteTables.Areas} WHERE id = ?`, areaId);
    return query.pop();
  }

  // --------------------------------------------------
  // Comments
  // --------------------------------------------------

  async getCommentById(id: string): Promise<IPSComment> {
    const [firstOrDefault] = await this.query(`SELECT * FROM ${SQLiteTables.Comments} WHERE id = ?`, id);
    return firstOrDefault;
  }

  async getDefectCommentsWhereMode(mode: MigrationStatus | MigrationStatus[], projectId?: string): Promise<IPSDefectComment[]> {
    const args: any[] = [];
    const filter = {
      'c.mode': mode,
    };

    if (projectId) {
      filter['c.project'] = projectId;
    }

    const condition = Builders.Filter(filter, args);

    return await this.query(
      `SELECT dc.* FROM comments c, defectComments dc WHERE c.id = dc.commentId AND ${condition}`,
      ...args
    );
  }

  async getCommentsWhereDefect(defectId: string): Promise<IPSComment[]> {
    return await this.query(
      `SELECT c.* FROM comments c, defectComments dc WHERE c.id = dc.commentId AND dc.defectId = ?"`,
      defectId
    );
  }

  async getCommentsWithUserWhereDefect(defectId: string): Promise<IPSCommentWithUser[]> {
    return this.query(
      `SELECT c.*, u.json as jsonUser FROM ${SQLiteTables.Users} u, ${SQLiteTables.Comments} c, ${SQLiteTables.DefectComments} dc WHERE dc.commentId = c.id AND dc.defectId = ? AND c.editorId = u.id`,
      defectId
    );
  }

  async getCommentsWithUserWhereDefects(defectIds: string[]): Promise<IPSCommentWithUserAndDefectId[]> {
    const distinctIds = defectIds.filter(i => i).distinct();
    const placeholders = distinctIds.map(() => '?').join(',');

    return this.query(
      `SELECT c.*, dc.defectId, u.json as jsonUser
      FROM ${SQLiteTables.DefectComments} dc
      JOIN ${SQLiteTables.Comments} c ON c.id = dc.commentId
      JOIN ${SQLiteTables.Users} u ON u.id = c.editorId
      WHERE dc.defectId IN (${placeholders})`,
      ...distinctIds
    );
  }

  async getCommentsWhereModeAndDefect(mode: MigrationStatus | MigrationStatus[], defectId?: string): Promise<IPSComment[]> {
    const args: any[] = [];
    const filter = {
      'c.mode': mode,
    };

    if (defectId) {
      filter['dc.defectId'] = defectId;
    }

    const condition = Builders.Filter(filter, args);

    return await this.query(
      `SELECT c.* FROM comments c, defectComments dc WHERE c.id = dc.commentId AND ${condition}`,
      ...args
    );
  }

  // --------------------------------------------------
  // Project Organizations
  // --------------------------------------------------

  async getProjectOrganizations(projectId: string): Promise<IPSProjectOrganization[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.ProjectOrganizations} WHERE project = ?`, projectId);
  }

  // --------------------------------------------------
  // Project Organization Crafts
  // --------------------------------------------------

  async getProjectOrganizationCrafts(projectOrganizationId: string): Promise<IPSProjectOrganizationCraft[]> {
    return await this.query(
      `SELECT * FROM ${SQLiteTables.ProjectOrganizationCrafts} WHERE projectOrganizationId = ?`,
      projectOrganizationId
    );
  }

  // --------------------------------------------------
  // Crafts
  // --------------------------------------------------

  async getCrafts(projectId?: string): Promise<IPSCraft[]> {
    const statementParts = [`SELECT * FROM ${SQLiteTables.Crafts}`];

    const args: any[] = [];
    if (projectId) {
      statementParts.push(`WHERE project = ?`);
      args.push(projectId);
    }

    return await this.query(statementParts.join(' '), ...args);
  }

  // --------------------------------------------------
  // Defects
  // --------------------------------------------------

  async getDefectById(id: string): Promise<IPSDefect> {
    const [firstOrDefault] = await this.query(`SELECT * FROM ${SQLiteTables.Defects} WHERE id = ?`, id);
    return firstOrDefault;
  }

  async getDefects(projectId: string): Promise<IPSDefect[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Defects} WHERE project = ?`, projectId);
  }

  async getDefectsWhereModified(projectId?: string) {
    if (projectId)
      return await this.query(`SELECT * FROM ${SQLiteTables.Defects} ${QueryCondition.modified} AND project = ?`, projectId);

    return await this.query(`SELECT * FROM ${SQLiteTables.Defects} ${QueryCondition.modified}`);
  }

  async getDefectsWhereMode(mode: MigrationStatus, projectId?: string): Promise<IPSDefect[]> {
    const args: any[] = [];
    const filter = {
      mode: mode,
    };

    if (projectId) {
      filter['project'] = projectId;
    }

    const condition = Builders.Filter(filter, args);

    return await this.query(`SELECT * FROM ${SQLiteTables.Defects} WHERE ${condition}`, ...args);
  }

  // --------------------------------------------------
  // Defect Types
  // --------------------------------------------------

  async getDefectTypes(projectId: string): Promise<IPSDefectType[]> {
    const args: any[] = [];
    const filter = {};

    if (projectId) {
      filter['project'] = projectId;
    }

    const condition = Builders.Filter(filter, args);

    return await this.query(`SELECT * FROM ${SQLiteTables.DefectTypes} WHERE ${condition}`, ...args);
  }

  // --------------------------------------------------
  // Defect Reasons
  // --------------------------------------------------

  async getDefectReasons(projectId: string): Promise<IPSReason[]> {
    const args: any[] = [];
    const filter = {};

    if (projectId) {
      filter['project'] = projectId;
    }

    const condition = Builders.Filter(filter, args);

    return await this.query(`SELECT * FROM ${SQLiteTables.DefectReasons} WHERE ${condition}`, ...args);
  }

  // --------------------------------------------------
  // DriveItems
  // --------------------------------------------------

  async getDriveItems(projectId?: string) {
    if (projectId) {
      return await this.query(`SELECT * FROM ${SQLiteTables.DriveItems} WHERE project = ?`, projectId);
    }

    return await this.query(`SELECT * FROM ${SQLiteTables.DriveItems}`);
  }

  async getDriveItemsWhereMode(mode: MigrationStatus, projectId?: string) {
    const args: any[] = [];
    const filter = {
      mode: mode,
    };

    if (projectId) {
      filter['project'] = projectId;
    }

    const condition = Builders.Filter(filter, args);

    return await this.query(`SELECT * FROM ${SQLiteTables.DriveItems} WHERE ${condition}`, ...args);
  }

  async getDriveItemsWhereModeIsInsertAndResource(projectId: string, resource: string) {
    return await this.query(
      `SELECT di.* FROM ${SQLiteTables.DriveItems} di, ${SQLiteTables.Defects} d WHERE di.relatedEntityId = d.id AND d.mode != "${MigrationStatus.INSERT}" AND di.mode = "${MigrationStatus.INSERT}" AND di.resource = ? AND di.project = ?`,
      resource,
      projectId
    );
  }

  async getDriveItemsWhereResourceAndId(projectId: string, resource: string, id: string): Promise<IPSDriveItem[]> {
    return await this.query(
      `SELECT * FROM ${SQLiteTables.DriveItems} WHERE project = ? AND resource = ? AND id = ?`,
      projectId,
      resource,
      id
    );
  }

  async getDriveItemsWhereModeIsInsertAndPath(projectId: string, path: string, resource: string) {
    return await this.query(
      `SELECT * FROM ${SQLiteTables.DriveItems} where mode = "${MigrationStatus.INSERT}" AND resource = ? AND path = ? AND project = ?`,
      resource,
      path,
      projectId
    );
  }

  // --------------------------------------------------
  // Regions
  // --------------------------------------------------

  async getRegionsWhereArea(areaId: string): Promise<IPSRegion[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Regions} WHERE areaId = ?`, areaId);
  }

  // --------------------------------------------------
  // Floors
  // --------------------------------------------------

  async getFloorWhereId(floorId: string): Promise<IPSFloor> {
    const query = await this.query(`SELECT * FROM ${SQLiteTables.Floors} WHERE id = ?`, floorId);
    return query.pop();
  }

  async getFloorsWhereArea(areaId: string): Promise<IPSFloor[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Floors} WHERE areaId = ?`, areaId);
  }

  // --------------------------------------------------
  // Organizations
  // --------------------------------------------------

  async getOrganizations(projectId: string): Promise<IPSOrganization[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Organizations} WHERE project = ?`, projectId);
  }

  // --------------------------------------------------
  // Rooms
  // --------------------------------------------------

  async getRoomsWhereId(roomId: string): Promise<IPSRoom[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Rooms} WHERE Id = ?`, roomId);
  }

  async getRoomsWhereFloor(floorId: string): Promise<IPSRoom[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Rooms} WHERE floorId = ?`, floorId);
  }

  // --------------------------------------------------
  // Zones
  // --------------------------------------------------

  async getZoneGroups(projectId: string): Promise<IPSZoneGroup[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.ZoneGroups} WHERE project = ?`, projectId);
  }

  async getZones(projectId: string): Promise<IPSZone[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Zones} WHERE project = ?`, projectId);
  }

  async getZonesWhereZoneGroup(zoneGroupId: string): Promise<IPSZone[]> {
    return await this.query(`SELECT * FROM ${SQLiteTables.Zones} WHERE zoneGroupId = ?`, zoneGroupId);
  }

  // --------------------------------------------------
  // Schema
  // --------------------------------------------------

  async getSchemaWhereType(projectId: string, type: ConfigType) {
    const [firstOrDefault] = await this.query('SELECT * FROM schema WHERE project = ? AND type = ?', projectId, type);
    return firstOrDefault;
  }

  // --------------------------------------------------
  // ProjectUsers
  // --------------------------------------------------

  async getProjectUsersWithUser(projectId: string): Promise<IPSUserWithProjectUserModel[]> {
    const users = await this.query(
      `SELECT u.*, cu.json "projectUserModel" FROM ${SQLiteTables.Users} u, ${SQLiteTables.ProjectUsers} cu WHERE u.project = ? AND u.id = cu.userId`,
      projectId
    );
    return users;
  }
}
