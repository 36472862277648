import { Migration as M211013 } from './migrations/M211013_Initial';
import { Migration as M220414 } from './migrations/M220414_ProjectName';
import { Migration as M220906 } from './migrations/M220906_ResourceIdentifiers';
import { Migration as M240229 } from './migrations/M240229_Zones';

import { capSQLiteSet, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { StatementParameters } from './definitions';
import { AppConfigService } from '../../app-config/app-config.service';
import { IQueryWrapper } from '../offline-query-wrapper';
import { SyncClient } from '@app/api';
import * as Sentry from '@sentry/angular';

export * from './definitions';

export enum SQLiteTables {
  SyncErrors = 'syncErrors',
  Metadata = 'metadata',
  Resources = 'resources',
  Projects = 'projects',
  Uploads = 'uploads',
  Areas = 'areas',
  Users = 'users',
  Rooms = 'rooms',
  Crafts = 'crafts',
  DefectReasons = 'defectReasons',
  Floors = 'floors',
  Regions = 'regions',
  ZoneGroups = 'ZoneGroups',
  Zones = 'Zones',
  Comments = 'comments',
  Organizations = 'organizations',
  Defects = 'defects',
  DefectTypes = 'defectTypes',
  DefectComments = 'defectComments',
  Schema = 'schema',
  DriveItems = 'driveItems',
  ProjectUsers = 'projectUsers',
  ProjectPrivileges = 'projectPrivileges',
  ProjectOrganizations = 'projectOrganizations',
  ProjectOrganizationCrafts = 'projectOrganizationCrafts',
}

export const Migrations = [M211013, M220414, M220906, M240229];

export enum MigrationStatus {
  INSERT = 'INSERT',
  EXISTS = 'EXISTS',
  UPDATE = 'UPDATE',
}

export async function migrateIfRequired(connection: SQLiteDBConnection, queryWrapper: IQueryWrapper, syncClient: SyncClient) {
  const version = await connection.getVersion();
  const breadcrumbs: Sentry.Breadcrumb[] = [];

  breadcrumbs.push({
    category: 'sqlite',
    data: {
      version: version,
      connectionName: connection.getConnectionDBName(),
    },
    message: 'Update Database with migrations: Begin',
  });

  try {
    // connection already opened on ctor
    // await connection.open();

    const index = 1;
    // initial version is 0 from sqlite, even in a blank database
    let user_version = Math.max(index, version.version || index);

    for (const migration of Migrations.slice(user_version - index)) {
      const statements: capSQLiteSet[] = [];

      breadcrumbs.push({
        category: 'sqlite',
        message: 'Update Migration: Begin',
        data: {
          migration: migration.name,
        },
      });

      for (const statement of migration.getStatements() ?? []) {
        statements.push({
          statement: statement.query,
          values: StatementParameters(statement),
        });
      }

      breadcrumbs.push({
        category: 'sqlite',
        message: 'execute set',
        data: {
          statements: statements.slice(0),
          migration: migration.name,
        },
      });

      await connection.executeSet(statements.splice(0), false);

      breadcrumbs.push({
        category: 'sqlite',
        message: 'execute update tables',
        data: {
          migration: migration.name,
        },
      });

      if (migration.updateData) {
        await migration.updateData(connection, syncClient);
      }

      statements.push({
        statement: 'PRAGMA USER_VERSION = ' + ++user_version,
        values: [], // empty array must be present
      });

      breadcrumbs.push({
        category: 'sqlite',
        message: 'execute update version',
        data: {
          migration: migration.name,
        },
      });

      await connection.executeSet(statements.splice(0), false);

      breadcrumbs.push({
        category: 'sqlite',
        message: 'Update Migration: Complete',
        data: {
          migration: migration.name,
        },
      });
    }

    for (const x of breadcrumbs) {
      Sentry.addBreadcrumb(x);
    }

    Sentry.captureMessage('Update Database with migrations: Complete');
  } catch ($err) {
    for (const x of breadcrumbs) {
      Sentry.addBreadcrumb(x);
    }

    // console.error('migrateIfRequired', $err);

    throw $err;
  } finally {
    // await connection.close();
  }
}
