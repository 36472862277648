import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UserModel, UserSessionModel } from '@app/api';
import { ApiUrlService, AppConfigService } from '@app/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, of, pairwise, share, switchMap } from 'rxjs';

interface UserData {
  id?: string;
  modifiedOn?: Date;
}

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent implements OnDestroy {
  @Input() sizeRem: number;
  @Input() tooltip: string;

  @Input() set user(user: UserModel) {
    this.userData$.next(user);
  }

  @Input() set userSession(userSession: UserSessionModel) {
    this.userData$.next({ id: userSession.userId, modifiedOn: userSession.userModifiedOn });
  }

  @Input() set userId(id: string) {
    this.userData$.next({ id });
  }

  @Output() imageClicked = new EventEmitter();

  userImageUrl$: Observable<string>;
  private userData$: BehaviorSubject<UserData>;

  constructor(apiUrlService: ApiUrlService, httpClient: HttpClient) {
    const useXhrRequest = CapacitorUtils.isApp();

    this.userData$ = new BehaviorSubject<UserData>(null);
    this.userImageUrl$ = this.userData$.pipe(
      distinctUntilChanged(undefined, data => data?.id),
      switchMap(userData => {
        const { id, modifiedOn } = userData ?? {};
        if (!id) return of(null);

        const userImageUrl = `${AppConfigService.settings.api.url}/user/${id}/image${
          modifiedOn ? `?ts=${modifiedOn.getTime()}` : ''
        }`;
        return !useXhrRequest
          ? of(apiUrlService.replaceApiFQDN(userImageUrl))
          : httpClient.get(userImageUrl, { responseType: 'blob' }).pipe(map(blob => URL.createObjectURL(blob)));
      }),
      share()
    );

    if (useXhrRequest) {
      this.userImageUrl$
        .pipe(
          pairwise(),
          map(([oldUrl, _]) => oldUrl),
          filter(oldUrl => oldUrl != null)
        )
        .subscribe(oldUrl => {
          URL.revokeObjectURL(oldUrl);
        });
    }
  }

  async ngOnDestroy() {
    this.userData$.next(null);
  }
}
