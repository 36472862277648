import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
  AddHtmlNotificationModel,
  AddIssueViewpointModel,
  AddOrUpdateConstructionDiaryModel,
  AddOrUpdateDefectModel,
  AddOrUpdateDriveItemPrivilegeModel,
  AddOrUpdateIssueCommentModel,
  AddOrUpdateIssueModel,
  AreaModel,
  AssignOrganizationModel,
  AttachmentClient,
  AttributeModel,
  AuthUserClient,
  BcfCodeModel,
  BcfVersion,
  BcfViewpointModel,
  BimBookmarkCoreModel,
  BimBookmarkCreateModel,
  BimBookmarkModel,
  BimProjectBookmarkClient,
  C4ApiFilterDefinition,
  CallbackResponse,
  CategoryModel,
  ChangeDefectModel,
  ChangePasswordModel,
  ChangeUserDataModel,
  ClientConsentModel,
  CodeModel,
  CommentModel,
  ConfigType,
  ConflictBehavior,
  ConstructionDiaryCreationInfoModel,
  ConstructionDiaryModel,
  ConstructionDiaryProjectSettingsModel,
  CraftClient,
  CraftModel,
  CreateOrAssignProjectUserModel,
  CreateOrUpdateUserModel,
  CustomizationModel,
  CustomizationSettingsModel,
  DailyWeatherModel,
  DefectModel,
  DefectProjectSettingsModel,
  DefectReasonClient,
  DefectReasonModel,
  DefectTypeClient,
  DefectTypeModel,
  DownloadDriveItemsRequest,
  DrawToSignDataModel,
  DriveActionMetadata,
  DriveClientExtended,
  DriveGenerateDownloadUrlResultModel,
  DriveGetLogsFilterDefinition,
  DriveItemChangeSetModel,
  DriveItemLogModel,
  DriveItemModel,
  DriveItemMoveModel,
  DriveItemPrivilegesModel,
  DriveItemTagsUpdateModel,
  DriveUploadChunkResultModel,
  DriveUploadResultModel,
  FileParameter,
  FilePreviewSpec,
  FileResponse,
  FloorModel,
  GalleryDriveItemModel,
  GeneralProjectSettingsModel,
  GenerateReportModel,
  GenerateZipDownloadUrlModel,
  GeoJSONFeatureCollection,
  GeoJSONRelationType,
  GetUserDataForAssignModel,
  IBcfViewpointModel,
  IssueLabelClient,
  IssueModel,
  IssueModels,
  IssueProjectSettingsModel,
  IssueStageClient,
  IssueTypeClient,
  LRTaskStatus,
  LRTasksClient,
  LeanBryntumSyncModel,
  LeanBryntumSyncResponseModel,
  LeanPhaseModel,
  LeanProjectClient,
  LeanProjectImport,
  LeanProjectPhaseClient,
  LeanProjectSettingsModel,
  LeanProjectSimulationClient,
  LeanProjectSpecialDatesClient,
  LeanProjectSwimlaneClient,
  LeanProjectWorkpackageClient,
  LeanProjectWorkpackageSequenceClient,
  LeanProjectWorkpackageTemplatesClient,
  LeanSimulationFeaturesRequest,
  LeanSimulationFindPlanRequest,
  LeanSimulationMoveElementsRequest,
  LeanSimulationPlansRequest,
  LeanSpecialDateModel,
  LeanSwimlaneModel,
  LeanTenantClient,
  LeanTenantPhaseClient,
  LeanTenantSwimlaneClient,
  LeanTenantWorkpackageSequenceClient,
  LeanTenantWorkpackageTemplatesClient,
  LeanWorkpackageModel,
  LeanWorkpackageSequenceModel,
  LeanWorkpackageTemplateModel,
  LeanWorkpackagesModel,
  LegacyClient,
  ModuleType,
  MultiStatusResult,
  NotificationClientExtended,
  NotificationModel,
  NotificationResultModel,
  NotificationSendType,
  NotificationStateModel,
  OAuthTokenClient,
  OrganizationClient,
  OrganizationModel,
  PasswordComplexityModel,
  PasswordErrorModel,
  PasswordErrorType,
  PlanClient,
  PlanComparisonStatus,
  PlanSchemaDefinition,
  PlanSchemaMetadata,
  PlanSchemaModel,
  PlanSchemaModelWithState,
  PlanSchemaTemplateClient,
  PrivilegeEnum,
  ProjectATrustSignClient,
  ProjectClient,
  ProjectConfigModel,
  ProjectConfigModelWithPrivileges,
  ProjectConstructionDiaryClient,
  ProjectDefectClient,
  ProjectDrawToSignClient,
  ProjectGalleryClient,
  ProjectIssueClient,
  ProjectManagementClient,
  ProjectModel,
  ProjectNotificationClient,
  ProjectOrganizationClient,
  ProjectOrganizationCraftClient,
  ProjectPlanClient,
  ProjectReportClient,
  ProjectResourceClient,
  ProjectRoomAttributeClient,
  ProjectRoomBookClient,
  ProjectRoomCategoryClient,
  ProjectRoomTemplateClient,
  ProjectSettingsClient,
  ProjectState,
  ProjectUserClient,
  ProjectUserModel,
  ProjectWithUserSettingsModel,
  PropertyBagClient,
  PropertyBagModel,
  PropertyBagType,
  ReceiverModel,
  RegionModel,
  RemarkTypeClient,
  ResourceIdentifier,
  RmFileContent,
  RoomAttributeClient,
  RoomCategoryClient,
  RoomModel,
  RoomTemplateClient,
  RoomTemplateModel,
  SendOnboardModel,
  SequenceModel,
  SessionClient,
  SetPasswordModel,
  SetProjectUserRolesModel,
  SettingsModel,
  SetupCheckTenantModel,
  SetupClient,
  SetupConsentInfoModel,
  SetupInfoModel,
  ShareDefectModel,
  ShareDriveModel,
  ShareEntityModel,
  SignDocumentResultModel,
  SignedUrlConversionType,
  SignedUrlResultModel,
  SnapshotAreaModel,
  StandardTextClient,
  StandardTextModel,
  SwaggerException,
  TeamConfigMetadata,
  TeamConfigTemplateClient,
  TeamRole,
  TenantClient,
  TenantFeaturesModel,
  TenantInfoModel,
  TransferFileFormat,
  UpdateProjectUserAssignmentsModel,
  UpdateUserSettingsModel,
  UserClient,
  UserFilterModel,
  UserModel,
  UserSessionModel,
  WeatherClient,
  XeokitDataClient,
  XeokitProjectModel,
  XeokitProjectsModel,
  XktListModel,
  ZoneClient,
  ZoneGroupClient,
  ZoneGroupModel,
  ZoneModel,
} from '@app/api';
import { Utils } from '@app/core/utils';
import { DataCache } from '@app/core/utils/DataCache';
import * as moment from 'moment';
import { Moment } from 'moment';
import {
  BehaviorSubject,
  catchError,
  defer,
  distinctUntilChanged,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { ISnapshotLoginState } from '../../ISnapshotLoginState';
import { AppConfigService } from '../app-config';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocalStorageService, ProjectService } from '../globals';
import { BridgeService } from '../globals/bridge-service';
import { LogService } from '../log/log.service';
import { OfflineService } from '../offline';
import { ApiAuthenticationSettings } from './api-authentication-settings';
import {
  DriveItemsResult,
  ExtendedCategoryModel,
  NotificationStatusUpdate,
  RecentFiles,
  StructureType,
} from './api-interfaces';
import { PasswordUpdateError } from './password-update-error';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';

enum AppCacheKey {
  authenticationSettings = 'authenticationSettings',
  issueLabels = 'issueLabels',
  issueStages = 'issueStages',
  issueTypes = 'issueTypes',
  passwordComplexityModel = 'passwordComplexityModel',
  privileges = 'privileges',
  tenantInfo = 'tenantInfo',
  tenantFeatures = 'tenantFeatures',
  userImage = 'userImage',
  userSession = 'userSession',
  view = 'tableView',
}

/**
 * Use in combination with project id!!!
 */
enum ProjectCacheKey {
  privileges = 'pPrivileges',
  project = 'pProject',
  driveItems = 'pDriveItems',
  planschema = 'pPlanSchema',
  roles = 'pRoles',
  notifcationUsers = 'pNotificationUsers',
  recentDocuments = 'pRecentDocuments',
  resourceIdentifiers = 'pResourceIdentifiers',
}

export const MODULE_TYPE$ = new InjectionToken<Subject<ModuleType>>('MODULE_TYPE$', {
  providedIn: 'root',
  factory: () => new BehaviorSubject<ModuleType>(null),
});

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private isActiveModuleOfflineCapable: boolean = false;
  private projectId: string;
  private cache = new DataCache();
  private availableCompanies: ProjectWithUserSettingsModel[];
  private passwordChangeRequired: boolean;
  private userDataChangeRequired: boolean;
  private userSettingsUpdatedSource = new Subject<void>();
  private loginStateChangedSource = new Subject<ISnapshotLoginState>();
  private notificationStatusUpdateSource = new Subject<NotificationStatusUpdate>();
  private appDeepLinkSlugCookieId = AppConfigService.settings.cookiePrefix + Utils.appDeepLinkSlugCookieId;

  browserCache: object = {};
  userSettingsUpdated = this.userSettingsUpdatedSource.asObservable();
  loginStateChanged = this.loginStateChangedSource.asObservable();
  notificationStatusUpdate = this.notificationStatusUpdateSource.asObservable();

  constructor(
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private log: LogService,
    bridge: BridgeService,
    private offlineService: OfflineService,
    private projectService: ProjectService,

    private attachmentClient: AttachmentClient,
    private authenticationClient: AuthUserClient,
    private bimProjectBookmarkClient: BimProjectBookmarkClient,
    private craftClient: CraftClient,
    private defectReasonClient: DefectReasonClient,
    private defectTypeClient: DefectTypeClient,
    private issueLabelClient: IssueLabelClient,
    private issueStageClient: IssueStageClient,
    private issueTypeClient: IssueTypeClient,
    private leanPhasesClient: LeanTenantPhaseClient,
    private leanProjectClient: LeanProjectClient,
    private leanProjectPhasesClient: LeanProjectPhaseClient,
    private leanProjectSpecialDatesClient: LeanProjectSpecialDatesClient,
    private leanProjectSwimlaneClient: LeanProjectSwimlaneClient,
    private leanProjectWorkpackageClient: LeanProjectWorkpackageClient,
    private leanProjectWorkpackageSequenceClient: LeanProjectWorkpackageSequenceClient,
    private leanProjectWorkpackageTemplateClient: LeanProjectWorkpackageTemplatesClient,
    private leanSwimlaneClient: LeanTenantSwimlaneClient,
    private leanTenantClient: LeanTenantClient,
    private leanWorkpackageSequenceClient: LeanTenantWorkpackageSequenceClient,
    private leanWorkpackageTemplateClient: LeanTenantWorkpackageTemplatesClient,
    private legacyClient: LegacyClient,
    private lrtTaskClient: LRTasksClient,
    private notificationClient: NotificationClientExtended,
    private oauthClient: OAuthTokenClient,
    private organizationClient: OrganizationClient,
    private planClient: PlanClient,
    private planSchemaTemplateClient: PlanSchemaTemplateClient,
    private projectATrustSignClient: ProjectATrustSignClient,
    private projectClient: ProjectClient,
    private projectDefectClient: ProjectDefectClient,
    private projectDiaryClient: ProjectConstructionDiaryClient,
    private projectDrawToSignClient: ProjectDrawToSignClient,
    private projectDriveClient: DriveClientExtended,
    private projectGalleryClient: ProjectGalleryClient,
    private projectIssueClient: ProjectIssueClient,
    private projectLeanSpecialDatesClient: LeanProjectSpecialDatesClient,
    private projectLeanWorkpackageClient: LeanProjectWorkpackageClient,
    private projectLeanSimulationClient: LeanProjectSimulationClient,
    private projectManagementClient: ProjectManagementClient,
    private projectNotificationClient: ProjectNotificationClient,
    private projectOrganizationClient: ProjectOrganizationClient,
    private projectOrganizationCraftClient: ProjectOrganizationCraftClient,
    private projectPlanClient: ProjectPlanClient,
    private projectReportClient: ProjectReportClient,
    private projectResourceClient: ProjectResourceClient,
    private projectRoomAttributeClient: ProjectRoomAttributeClient,
    private projectRoomBookClient: ProjectRoomBookClient,
    private projectRoomCategoryClient: ProjectRoomCategoryClient,
    private projectRoomTemplateClient: ProjectRoomTemplateClient,
    private projectSettingsClient: ProjectSettingsClient,
    private projectUserClient: ProjectUserClient,
    private propertyBagClient: PropertyBagClient,
    private remarkTypeClient: RemarkTypeClient,
    private roomAttributeClient: RoomAttributeClient,
    private roomCategoryClient: RoomCategoryClient,
    private roomTemplateClient: RoomTemplateClient,
    private sessionClient: SessionClient,
    private setupClient: SetupClient,
    private standardTextClient: StandardTextClient,
    private tasksClient: LRTasksClient,
    private teamConfigTemplateClient: TeamConfigTemplateClient,
    private tenantClient: TenantClient,
    private userClient: UserClient,
    private weatherClient: WeatherClient,
    private xeokitDataClient: XeokitDataClient,
    private zoneGroupClient: ZoneGroupClient,
    private zoneClient: ZoneClient,

    @Inject(MODULE_TYPE$) moduleType$: Observable<ModuleType>
  ) {
    moduleType$.pipe(distinctUntilChanged()).subscribe(moduleType => {
      this.isActiveModuleOfflineCapable = CapacitorUtils.isApp() && this.offlineService.offlineModules.includes(moduleType);
    });

    bridge.registerApiService(this);

    this.authenticationService.loginStateChanged.subscribe(state => {
      this.log.debug(`Authentication state changed: reset ApiService`, state);
      this.cache.clear();
      this.passwordChangeRequired = state.passwordChangeRequired;
      this.userDataChangeRequired = state.userDataChangeRequired;
      this.availableCompanies = state.availableCompanies;

      this.loginStateChangedSource.next({
        authenticated: state.authenticated,
        with: state.with,
      });
    });

    this.projectService.projectId$.subscribe(projectId => {
      this.projectId = projectId;
      // this.cache.remove(appCacheKey.privilegesProject);
    });
  }

  getPasswordChangeRequired(): boolean {
    return this.passwordChangeRequired;
  }

  getUserDataChangeRequired(): boolean {
    return this.userDataChangeRequired;
  }

  // #region Tenant

  async getTenantUrl() {
    const tenantName = await this.localStorageService.getItem(Utils.tenantNameStorageId);

    if (!tenantName) return null;

    try {
      const offlineCall = async () => {
        const tenantInfo = await this.offlineService.getTenantInfo();
        if (tenantInfo?.name?.toLowerCase() != tenantName.toLowerCase()) throw new Error('Invalid Tenant found!');
        return tenantInfo.publicUrl;
      };

      return this.offlineService.isOffline
        ? await offlineCall()
        : await firstValueFrom(this.tenantClient.getTenantUrlByName(tenantName)).catch(e =>
            this.handleOfflineErrorAsync(e, offlineCall)
          );
    } catch (e) {
      await this.localStorageService.removeItem(Utils.tenantNameStorageId);
      return null;
    }
  }

  async setTenant(tenantName: string) {
    const tenantUrl = firstValueFrom(this.tenantClient.getTenantUrlByName(tenantName));
    await this.localStorageService.setItem(Utils.tenantNameStorageId, tenantName);
    return tenantUrl;
  }

  async removeSavedTenant() {
    await this.localStorageService.removeItem(Utils.tenantNameStorageId);
    this.cache.remove(AppCacheKey.tenantInfo);
  }

  async getTenantFeatures(): Promise<TenantFeaturesModel> {
    this.cache.registerIfNeeded(AppCacheKey.tenantFeatures, () => {
      return this.tenantClient.getFeatures().toPromise();
    });

    return (await this.cache.get(AppCacheKey.tenantFeatures)) as TenantFeaturesModel;
  }

  async getTenantInfo(force = false): Promise<TenantInfoModel> {
    if (force) this.cache.remove(AppCacheKey.tenantInfo);

    this.cache.registerIfNeeded(AppCacheKey.tenantInfo, () => {
      return firstValueFrom(
        this.tenantClient.getTenantInformation().pipe(
          tap(tenantInfo => {
            if (CapacitorUtils.isApp()) this.offlineService.setTenantInfo(tenantInfo);
          })
        )
      );
    });

    const offlineCall = () => this.offlineService.getTenantInfo();
    return this.offlineService.isOffline
      ? await offlineCall()
      : await this.cache.get<TenantInfoModel>(AppCacheKey.tenantInfo).catch(e => this.handleOfflineErrorAsync(e, offlineCall));
  }

  setAppLinkSlugCookie(slug: string) {
    Utils.setCookie(this.appDeepLinkSlugCookieId, slug, 1, AppConfigService.settings.publicDomain);
  }

  getAppLinkSlugCookie(): string {
    return Utils.getCookie(this.appDeepLinkSlugCookieId);
  }

  removeAppLinkSlugCookie() {
    Utils.deleteCookie(this.appDeepLinkSlugCookieId, AppConfigService.settings.publicDomain);
  }

  async getCustomization(): Promise<CustomizationModel> {
    const offlineCall = () => this.offlineService.getCustomizations();

    return this.offlineService.isOffline
      ? await offlineCall()
      : await firstValueFrom(
          this.tenantClient.getCustomizations().pipe(
            tap(customizations => {
              if (CapacitorUtils.isApp()) this.offlineService.setCustomizations(customizations);
            })
          )
        ).catch(e => this.handleOfflineErrorAsync(e, offlineCall));
  }

  async getCustomizationSettings(): Promise<CustomizationSettingsModel> {
    return await this.tenantClient.getCustomizationSettings().toPromise();
  }

  async updateCustomizationSettings(model: CustomizationSettingsModel): Promise<void> {
    await this.tenantClient.addOrUpdateCustomizationSettings(model).toPromise();
  }

  async getTenantSettings(): Promise<SettingsModel> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.tenantClient.getTenantSettings()),
      async () => await this.offlineService.getTenantSettings()
    );
  }

  async saveTenantSettings(model: SettingsModel) {
    await this.tenantClient.saveTenantSettings(model).toPromise();
  }

  async getPasswordComplexityModel(): Promise<PasswordComplexityModel> {
    this.cache.registerIfNeeded(AppCacheKey.passwordComplexityModel, () => {
      return this.tenantClient.getPasswordComplexity().toPromise();
    });

    return (await this.cache.get(AppCacheKey.passwordComplexityModel)) as PasswordComplexityModel;
  }

  async setupCheckTenant(forgetToken: boolean): Promise<SetupCheckTenantModel> {
    const response = await this.setupClient.checkTenant(forgetToken).toPromise();
    return response;
  }

  async updateTenantInformation(tenantInfoModel: SetupConsentInfoModel): Promise<void> {
    await this.setupClient.updateTenantInformation(tenantInfoModel).toPromise();
  }

  async setupInfo(): Promise<SetupInfoModel> {
    const response = await this.setupClient.info().toPromise();
    return response;
  }

  async getAuthenticationSettings(): Promise<ApiAuthenticationSettings> {
    this.cache.registerIfNeeded(AppCacheKey.authenticationSettings, async () => {
      return firstValueFrom(
        this.authenticationClient.getPublicProtocolInfo().pipe(map(protocolInfo => new ApiAuthenticationSettings(protocolInfo)))
      );
    });

    const offlineCall = async () => new ApiAuthenticationSettings({});
    return this.offlineService.isOffline
      ? await offlineCall()
      : await this.cache
          .get<ApiAuthenticationSettings>(AppCacheKey.authenticationSettings)
          .catch(e => this.handleOfflineErrorAsync(e, offlineCall));
  }

  // #endregion

  // #region Project

  async getProject(id: string): Promise<ProjectModel> {
    return await this.tryOfflineAsync(
      async () => {
        const key = this.generateProjectKey(ProjectCacheKey.project, id);
        this.cache.registerIfNeeded(key, async () => {
          return await firstValueFrom(this.projectClient.get(id));
        });

        return await this.cache.get<ProjectModel>(key);
      },
      async () => await this.offlineService.getProjectById(id),
      id
    );
  }

  async getProjects(): Promise<ProjectModel[]> {
    const projects = await this.projectClient.getAll().toPromise();

    projects.forEach(entry => {
      const key = this.generateProjectKey(ProjectCacheKey.project, entry.id);
      this.cache.registerIfNeeded(
        key,
        async () => {
          return await this.projectClient.get(entry.id).toPromise();
        },
        0,
        entry
      );
    });

    return projects;
  }

  async deleteProject(id: string) {
    await this.projectClient.delete(id).toPromise();
  }

  hasAccessToProject(projectId: string): Observable<boolean> {
    const offlineCall = defer(() => of(this.offlineService.isProjectOffline(projectId)));

    return this.offlineService.isOffline
      ? offlineCall
      : this.sessionClient.hasAccessToProject(projectId).pipe(this.handleOfflineError(offlineCall));
  }

  getAvailableProjects(): Observable<ProjectWithUserSettingsModel[]> {
    const offlineCall = defer(() => from(this.offlineService.getAvailableProjects()));

    return this.offlineService.isOffline
      ? offlineCall
      : this.sessionClient.getProjects().pipe(
          tap(projects => {
            for (const project of projects) {
              const key = this.generateProjectKey(ProjectCacheKey.project, project.id);
              this.cache.registerIfNeeded(
                key,
                async () => {
                  return await firstValueFrom(this.projectClient.get(project.id));
                },
                0,
                project
              );
            }
          }),
          this.handleOfflineError(offlineCall)
        );
  }

  async getProjectIdForGroupId(groupId: string): Promise<string> {
    const response = await this.sessionClient.getProjectIdForGroupId(groupId).toPromise();
    return response;
  }

  getOtherTenants() {
    const offlineCall = defer(() => of([] as TenantInfoModel[]));

    return this.offlineService.isOffline
      ? offlineCall
      : this.sessionClient.getOtherTenants().pipe(this.handleOfflineError(offlineCall));
  }

  async getProjectConfig(projectId: string): Promise<ProjectConfigModelWithPrivileges> {
    const response = await this.projectManagementClient.getTeamConfig(projectId).toPromise();
    return response;
  }

  async getAssignedUsersForProject(projectId: string = null): Promise<UserModel[]> {
    return await this.projectManagementClient.getAssignedUsers(projectId).toPromise();
  }

  async getAssignedProjectsForUser(userId: string): Promise<ProjectModel[]> {
    return await this.userClient.getAssignedProjects(userId).toPromise();
  }

  async saveProject(model: ProjectConfigModel) {
    const projectId = this.projectId;
    if (typeof projectId == 'string' && projectId != '') {
      const result = await this.projectClient.updateProject(projectId, model).toPromise();

      const rolesKey = this.generateProjectKey(ProjectCacheKey.roles, projectId);
      this.cache.remove(rolesKey);

      return result;
    } else {
      const result = await this.projectClient.createProject(model).toPromise();
      return result;
    }
  }

  async setProjectState(projectId: string, projectState: ProjectState) {
    await this.projectClient.setProjectState(projectId, projectState).toPromise();
  }

  removeCacheAffectedByProjectSettings(projectId: string = null) {
    projectId = projectId ?? this.projectId;

    this.cache.remove(this.generateProjectKey(ProjectCacheKey.privileges, projectId));
    this.cache.remove(this.generateProjectKey(ProjectCacheKey.project, projectId));
    this.cache.remove(this.generateProjectKey(ProjectCacheKey.resourceIdentifiers, projectId));
    this.removeCachedDriveItems(projectId);
  }

  async deleteTeamsTemplate(id: string) {
    return await this.teamConfigTemplateClient.delete(id).toPromise();
  }

  async getTeamsTemplates(): Promise<TeamConfigMetadata[]> {
    return await this.teamConfigTemplateClient.getMetaInfos().toPromise();
  }

  async getTemplateConfiguration(id: string): Promise<ProjectConfigModelWithPrivileges> {
    return await this.teamConfigTemplateClient.getDefinition(id).toPromise();
  }

  async getEmptyTemplateConfiguration(): Promise<ProjectConfigModelWithPrivileges> {
    return await this.teamConfigTemplateClient.getEmptyDefinition().toPromise();
  }

  async saveOrUpdateTemplateConfiguration(templateCfg: ProjectConfigModel) {
    return await this.teamConfigTemplateClient.saveOrUpdate(templateCfg).toPromise();
  }

  async saveOrUpdateProjectSchema(projectId: string, type: ConfigType, model: PlanSchemaModel) {
    return await this.projectManagementClient.setPlanSchema(projectId, type, model).toPromise();
  }

  async getProjectPlanSchema(projectId: string, schemaType: ConfigType): Promise<PlanSchemaModelWithState> {
    return await this.projectManagementClient.getPlanSchema(projectId, schemaType).toPromise();
  }

  async getModuleResourceKey(module: ModuleType, projectId = this.projectId) {
    const resourceIdentifiers = await this.getResourceIdentifiers(projectId);
    return resourceIdentifiers.find(i => i.moduleType === module)?.key?.name;
  }

  async getResourceIdentifiers(projectId: string = this.projectId): Promise<ResourceIdentifier[]> {
    return await this.tryOfflineAsync(
      async () => {
        const key = this.generateProjectKey(ProjectCacheKey.resourceIdentifiers, projectId);
        this.cache.registerIfNeeded(key, async () => {
          return await firstValueFrom(this.projectResourceClient.getResourceIdentifiers(projectId));
        });

        return await this.cache.get<ResourceIdentifier[]>(key);
      },
      async () => await this.offlineService.getResourceIdentifiers(projectId),
      projectId
    );
  }

  async getGeneralSettingsForProject(projectId: string = null): Promise<GeneralProjectSettingsModel> {
    return await this.projectSettingsClient.getGeneralSettingsForProject(projectId ?? this.projectId).toPromise();
  }

  async saveGeneralSettingsForProject(model: GeneralProjectSettingsModel) {
    await this.projectSettingsClient.saveGeneralSettingsForProject(this.projectId, model).toPromise();
    this.cache.remove(this.generateProjectKey(ProjectCacheKey.privileges, this.projectId));
    this.cache.remove(this.generateProjectKey(ProjectCacheKey.project, this.projectId));
  }

  async getDefectSettingsForProject(projectId: string = null): Promise<DefectProjectSettingsModel> {
    return await this.projectSettingsClient.getDefectSettingsForProject(projectId ?? this.projectId).toPromise();
  }

  async getDiarySettingsForProject(projectId: string = null): Promise<ConstructionDiaryProjectSettingsModel> {
    return await this.projectSettingsClient.getDiarySettingsForProject(projectId ?? this.projectId).toPromise();
  }

  async getIssueSettingsForProject(projectId: string = null): Promise<IssueProjectSettingsModel> {
    return await this.projectSettingsClient.getIssueSettingsForProject(projectId ?? this.projectId).toPromise();
  }

  async getLeanSettingsForProject(projectId: string = null): Promise<LeanProjectSettingsModel> {
    return await this.projectSettingsClient.getLeanSettingsForProject(projectId ?? this.projectId).toPromise();
  }

  async getProjectLogos(projectId: string = null) {
    return await this.projectClient.getLogos(projectId ?? this.projectId).toPromise();
  }

  // #endregion

  // #region Plan Schema

  async getPlansTemplates(): Promise<PlanSchemaMetadata[]> {
    if (this.projectId) {
      return await this.projectManagementClient.getSchemaMetaInfos(this.projectId).toPromise();
    }
    return await this.planSchemaTemplateClient.getMetaInfos().toPromise();
  }

  async getPlanSchemaByResource(resource: string, projectId = this.projectId): Promise<PlanSchemaDefinition> {
    return await this.tryOfflineAsync(
      async () => {
        const key = this.generateProjectKey(ProjectCacheKey.planschema, projectId, this.encodeResource(resource));
        this.cache.registerIfNeeded(key, async () => {
          return await firstValueFrom(this.planClient.getPlanDefinition(projectId, this.encodeResource(resource)));
        });

        return await this.cache.get<PlanSchemaDefinition>(key);
      },
      async () => await this.offlineService.getPlanSchemaForProject(projectId, this.encodeResource(resource)),
      projectId
    );
  }

  async saveOrUpdateSchema(model: PlanSchemaModel): Promise<string> {
    return await this.planSchemaTemplateClient.saveOrUpdate(model).toPromise();
  }

  async getPlanSchema(id: string): Promise<PlanSchemaModel> {
    return await this.planSchemaTemplateClient.getDefinition(id).toPromise();
  }

  async deleteSchema(guid: string) {
    await this.planSchemaTemplateClient.delete(guid).toPromise();
  }

  async convertPlan(modelId: string, fileExtension: string): Promise<LRTaskStatus> {
    return await this.projectPlanClient.convertToMBTiles(modelId, this.projectId, fileExtension).toPromise();
  }

  comparePlans(metadataId: string, compareId: string): Observable<PlanComparisonStatus> {
    return this.projectPlanClient.compare(metadataId, compareId, this.projectId);
  }

  async resolvePlan(fileId: string, projectId = this.projectId) {
    return await firstValueFrom(this.projectPlanClient.resolveModel(fileId, projectId));
  }

  async getGeoJSON(
    modelId: string,
    relatedType: GeoJSONRelationType,
    relatedId?: string,
    projectId = this.projectId
  ): Promise<GeoJSONFeatureCollection> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectPlanClient.getGeoJson(modelId, projectId, relatedType, relatedId)),
      async () => await this.offlineService.getGeoJson(modelId, projectId, relatedType, relatedId),
      projectId,
      false
    );
  }

  async putGeoJSON(modelId: string, geoJson: GeoJSONFeatureCollection, relatedType: GeoJSONRelationType, relatedId?: string) {
    return await this.projectPlanClient.putGeoJson(modelId, this.projectId, relatedType, relatedId, geoJson).toPromise();
  }

  // #endregion

  // #region Users

  async getUserPrivileges(projectId = this.projectId): Promise<PrivilegeEnum[]> {
    return !projectId ? await this.getGlobalUserPrivileges() : await this.getUserPrivilegesForProject(projectId);
  }

  async getUserSession(forceRefresh: boolean = false): Promise<UserSessionModel> {
    this.cache.registerIfNeeded(AppCacheKey.userSession, async () => {
      return await firstValueFrom(
        this.sessionClient.getUserSession().pipe(
          tap(userSession => {
            if (CapacitorUtils.isApp()) this.offlineService.setUserSession(userSession);
          })
        )
      );
    });

    const offlineCall = () => this.offlineService.getUserSession();
    return this.offlineService.isOffline
      ? await offlineCall()
      : await this.cache
          .get<UserSessionModel>(AppCacheKey.userSession, forceRefresh)
          .catch(e => this.handleOfflineErrorAsync(e, offlineCall));
  }

  async getUsers(): Promise<UserModel[]> {
    return await this.userClient.getAll().toPromise();
  }

  async getUser(id: string): Promise<UserModel> {
    return await this.userClient.get(id).toPromise();
  }

  async deleteUser(id: string) {
    await this.userClient.delete(id).toPromise();
  }

  async addOrUpdateUser(model: CreateOrUpdateUserModel): Promise<string> {
    const result = this.projectId
      ? await this.projectUserClient.updateUser(this.projectId, model).toPromise()
      : await this.userClient.save(model).toPromise();
    return result.userId;
  }

  async getAssignableUser(filter: UserFilterModel): Promise<UserModel[]> {
    return await this.projectUserClient.getAssignableUser(this.projectId, filter).toPromise();
  }

  async getAcceptedConsents(): Promise<ClientConsentModel[]> {
    return await this.userClient.getAcceptedConsents().toPromise();
  }

  async hasConsent(clientId: string, scope: string): Promise<boolean> {
    return await this.userClient.hasConsent(clientId, scope).toPromise();
  }

  async giveConsent(clientId: string, scope: string) {
    await this.userClient.giveConsent(clientId, scope).toPromise();
  }

  async revokeConsent(clientId: string, scope: string) {
    await this.userClient.revokeConsent(clientId, scope).toPromise();
  }

  async setTemporaryPassword(model: SetPasswordModel) {
    await this.userClient.setTemporaryPassword(model).toPromise();
  }

  async getUserForAssign(request: GetUserDataForAssignModel): Promise<UserModel> {
    return await this.projectUserClient.getUserDataForAssign(this.projectId, request).toPromise();
  }

  async updateProjectUserAssignments(model: UpdateProjectUserAssignmentsModel) {
    await this.projectClient.setAssignedUsers(model).toPromise();
  }

  async deleteTeamUser(team: ProjectUserModel): Promise<void> {
    this.cache.remove(this.generateProjectKey(ProjectCacheKey.notifcationUsers, this.projectId));
    await this.projectUserClient.delete(team.id, this.projectId).toPromise();
  }

  async updateUserRoles(userRoles: SetProjectUserRolesModel): Promise<void> {
    this.cache.remove(this.generateProjectKey(ProjectCacheKey.notifcationUsers, this.projectId));
    await this.projectUserClient.setRoles(this.projectId, userRoles).toPromise();
  }

  async assignNewMembers(newMembers: CreateOrAssignProjectUserModel[]): Promise<ProjectUserModel> {
    return await this.projectUserClient.assign(this.projectId, newMembers).toPromise();
  }

  async sendNewOnboard(onbardModel: SendOnboardModel): Promise<void> {
    await this.projectUserClient.sendNewOnboard(this.projectId, onbardModel).toPromise();
  }

  async getTeam(): Promise<ProjectUserModel[]> {
    return await this.projectUserClient.getAll(this.projectId).toPromise();
  }

  async getRoles(withHidden: boolean, withReadOnly: boolean, projectId: string = this.projectId): Promise<TeamRole[]> {
    return await this.tryOfflineAsync(
      async () => {
        const availableRoles = firstValueFrom(this.projectUserClient.getAvailableRoles(projectId, withHidden, withReadOnly));
        const useCache = !withHidden && !withReadOnly;
        if (!useCache) return await availableRoles;

        const key = this.generateProjectKey(ProjectCacheKey.roles, projectId);
        this.cache.registerIfNeeded(key, async () => {
          return await availableRoles;
        });

        return await this.cache.get<TeamRole[]>(key);
      },
      async () => await this.offlineService.getRolesForProject(projectId),
      projectId
    );
  }

  async getUserLogs(id: string): Promise<DriveItemLogModel[]> {
    return await this.projectUserClient.getUserLogs(id, this.projectId).toPromise();
  }

  async uploadUserImage(image?: string): Promise<void> {
    await this.userClient.setCurrentUserImage(image).toPromise();
    this.cache.remove(AppCacheKey.userSession);
    this.cache.removeWhereKeyStartsWith(AppCacheKey.userImage);
    this.userSettingsUpdatedSource.next();
  }

  async updateUserData(userData: ChangeUserDataModel): Promise<void> {
    await this.userClient.setCurrentUserData(userData).toPromise();
    this.cache.remove(AppCacheKey.userSession);
    this.userSettingsUpdatedSource.next();
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      await this.userClient
        .setCurrentUserPassword(
          new ChangePasswordModel({
            oldPassword,
            newPassword,
          })
        )
        .toPromise();
    } catch (e) {
      this.log.error('Failed to udpate password', e);
      if (Array.isArray(e)) {
        if (e[0] instanceof PasswordErrorModel) {
          if (PasswordErrorType.OldPasswordNotMatching === e[0].errorType) {
            throw PasswordUpdateError.WrongOldPassword;
          } else if (PasswordErrorType.NewPasswordEqualToOld === e[0].errorType) {
            throw PasswordUpdateError.NewPasswordMatchesOldPassword;
          } else if (
            PasswordErrorType.NotSupportedByConfiguration === e[0].errorType ||
            PasswordErrorType.NotSupportedByLoginType === e[0].errorType
          ) {
            throw PasswordUpdateError.NotSupported;
          }
          throw PasswordUpdateError.PasswordInvalid;
        }
      }

      throw PasswordUpdateError.Generic;
    }
  }

  async updateUserSettings(updateModel: UpdateUserSettingsModel) {
    await this.sessionClient.updateUserSettings(updateModel).toPromise();
    this.availableCompanies = undefined;
  }

  private async getGlobalUserPrivileges() {
    this.cache.registerIfNeeded(ProjectCacheKey.privileges, async () => {
      return await firstValueFrom(
        this.userClient.getUserPrivileges().pipe(
          tap(privileges => {
            if (CapacitorUtils.isApp()) this.offlineService.setUserPrivileges(privileges);
          })
        )
      );
    });

    const offlineCall = () => this.offlineService.getUserPrivileges();
    return this.offlineService.isOffline
      ? await offlineCall()
      : await this.cache
          .get<PrivilegeEnum[]>(ProjectCacheKey.privileges)
          .catch(e => this.handleOfflineErrorAsync(e, offlineCall));
  }

  private async getUserPrivilegesForProject(projectId: string): Promise<PrivilegeEnum[]> {
    return await this.tryOfflineAsync(
      async () => {
        const cacheKey = this.generateProjectKey(ProjectCacheKey.privileges, projectId);
        this.cache.registerIfNeeded(cacheKey, async () => {
          return await firstValueFrom(this.projectUserClient.getUserPrivileges(projectId));
        });

        return await this.cache.get<PrivilegeEnum[]>(cacheKey);
      },
      async () => await this.offlineService.getUserPrivilegesForProject(projectId),
      projectId
    );
  }

  // #endregion

  // #region Notifications

  async createNotification(model: AddHtmlNotificationModel, attachments: any[]) {
    await this.notificationClient
      .addHtmlNotificationEx(
        model,
        attachments.select(
          a =>
            <FileParameter>{
              fileName: a.name,
              data: a,
            }
        )
      )
      .toPromise();
  }

  async getUserForNotification(): Promise<ReceiverModel[]> {
    const key = this.generateProjectKey(ProjectCacheKey.notifcationUsers, this.projectId);
    this.cache.registerIfNeeded(key, () => {
      return this.projectNotificationClient.getReceivers(this.projectId).toPromise();
    });
    return (await this.cache.get(key)) as ReceiverModel[];
  }

  async shareFileNotifaction(fileNotification: ShareDriveModel): Promise<MultiStatusResult> {
    return await this.projectDriveClient
      .shareDriveNotification(this.projectId, this.encodeResource(''), fileNotification)
      .toPromise();
  }

  async markNotificationAsConfirmed(id: string) {
    try {
      await this.projectNotificationClient.markNotificationAsConfirmed(id, this.projectId).toPromise();
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        if (e.status === 404) {
          //means that item does not exist any more or is already marked as read - ignore in UI
          return;
        }
      }
      throw e;
    }
    this.notificationStatusUpdateSource.next({
      id,
      deleted: false,
      confirmed: true,
    });
  }

  async deleteNotification(id: string) {
    try {
      await this.projectNotificationClient.deleteNotification(id, this.projectId).toPromise();
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        if (e.status === 404) {
          //means that item does not exist any more or is already deleted - ignore in UI
          return;
        }
      }
      throw e;
    }
    this.notificationStatusUpdateSource.next({
      id,
      deleted: true,
      confirmed: false,
    });
  }

  async getNotifications(
    raisesEvent: boolean,
    onlyNotifyOnLogin: boolean,
    sendType: NotificationSendType = NotificationSendType.Input
  ): Promise<NotificationModel[]> {
    const result = await this.projectNotificationClient
      .notificationsAll(this.projectId, sendType, onlyNotifyOnLogin)
      .toPromise();
    if (raisesEvent) {
      this.notificationStatusUpdateSource.next({
        id: '',
        deleted: false,
        confirmed: false,
      });
    }
    return result;
  }

  async getNotificationAdditionalData(
    id: string,
    sendType: NotificationSendType = NotificationSendType.Input
  ): Promise<string> {
    return await this.projectNotificationClient.notificationAdditionalData(id, this.projectId, sendType).toPromise();
  }

  async getNotification(id: string, sendType: NotificationSendType = NotificationSendType.Input): Promise<NotificationModel> {
    return await this.projectNotificationClient.notifications(id, this.projectId, sendType).toPromise();
  }

  async getDefectsForNoficiation(notificationId: string, sendType: NotificationSendType) {
    return await this.projectNotificationClient.getDefectsForNotification(notificationId, this.projectId, sendType).toPromise();
  }

  async getIssuesForNoficiation(notificationId: string, sendType: NotificationSendType) {
    return await this.projectNotificationClient.getIssuesForNotification(notificationId, this.projectId, sendType).toPromise();
  }

  // #endregion

  // #region Property Bag (Custom Views)

  async getViews(type: PropertyBagType, key: string = null, projectId: string = null): Promise<PropertyBagModel[]> {
    return await this.tryOfflineAsync(
      async () => {
        const cacheKey = this.generateViewKey(type, key, projectId);

        this.cache.registerIfNeeded(cacheKey, async () => {
          return await firstValueFrom(this.propertyBagClient.get(type, key, projectId));
        });

        return await this.cache.get<PropertyBagModel[]>(cacheKey);
      },
      async () => await this.offlineService.getViews(projectId ?? key),
      projectId ?? key
    );
  }

  async createOrUpdateView(view: PropertyBagModel): Promise<string> {
    const key = this.generateViewKey(view.type, view.key, view.projectId);

    return await firstValueFrom(this.propertyBagClient.save(view).pipe(tap(() => this.cache.removeWhereKeyStartsWith(key))));
  }

  async deleteView(id: string, type: PropertyBagType): Promise<void> {
    const key = this.generateViewKey(type);

    await firstValueFrom(this.propertyBagClient.delete(id).pipe(tap(() => this.cache.removeWhereKeyStartsWith(key))));
  }

  // #endregion

  // #region Logs

  async getItemLogs(id: string, resource?: string | undefined): Promise<DriveItemLogModel[]> {
    const apiResult = await this.projectDriveClient
      .getItemLogs(this.projectId, id, this.encodeResource(resource), new DriveGetLogsFilterDefinition())
      .toPromise();
    return apiResult as DriveItemLogModel[];
  }

  async getReceiversForShareLog(logId: string, resource?: string | undefined): Promise<NotificationStateModel[]> {
    return await this.projectDriveClient
      .getReceiversForShareLog(logId, this.projectId, this.encodeResource(resource))
      .toPromise();
  }

  async getReceiversForTransaction(transactionId: string, resource?: string): Promise<NotificationStateModel[]> {
    return await this.projectDriveClient
      .getReceiversForTransaction(transactionId, this.projectId, this.encodeResource(resource))
      .toPromise();
  }

  // #endregion

  // #region Drive Items

  async getItemPrivileges(driveItemId: string, resource?: string | undefined): Promise<DriveItemPrivilegesModel> {
    return await this.projectDriveClient
      .getItemPrivileges(driveItemId, this.projectId, this.encodeResource(resource))
      .toPromise();
  }

  async setItemPrivileges(driveItemId: string, resource: string | undefined, path: string, mappings: Record<string, number>) {
    const privileges = new AddOrUpdateDriveItemPrivilegeModel({
      mappings,
    });

    await this.projectDriveClient
      .setItemPrivileges(driveItemId, this.projectId, this.encodeResource(resource), privileges)
      .toPromise();

    const key = this.generateProjectKey(ProjectCacheKey.driveItems, this.projectId, this.encodeResource(resource), path);

    this.cache.removeWhereKeyStartsWith(key);
  }

  getDriveItem(id: string, resource?: string | undefined): Observable<DriveItemModel> {
    return this.projectDriveClient.item(id, this.projectId, this.encodeResource(resource));
  }

  getMetadataDriveItems(resource: string, projectId = this.projectId) {
    return this.tryOffline(
      this.projectDriveClient.getMetadataItems(projectId, this.encodeResource(resource)),
      async () => await this.offlineService.getMetadataDriveItems(projectId, resource),
      projectId,
      false
    );
  }

  async getDriveItems(
    path: string,
    searchText?: string,
    removeCache: boolean = false,
    resource?: string | undefined,
    metadata?: DriveActionMetadata
  ): Promise<DriveItemsResult> {
    const projectId = this.projectId;
    path = this.normalizeDrivePath(path);

    if (removeCache) this.removeCachedDriveItems(projectId);

    if (Utils.isNullOrWhitespace(searchText)) {
      //normalize empty searchText
      searchText = undefined;
    }

    const filter = new C4ApiFilterDefinition();
    return await this.tryOfflineAsync(
      async () => {
        // do not cache filtered results! if we do, add also filter to key
        if (searchText !== undefined) {
          return firstValueFrom(
            this.projectDriveClient.items(this.projectId, path, false, searchText, this.encodeResource(resource), filter).pipe(
              map(apiResult => ({
                addFileAllowed: apiResult.metadata.addFileAllowed,
                addFolderAllowed: apiResult.metadata.addFolderAllowed,
                items: apiResult.content as DriveItemModel[],
              }))
            )
          );
        }

        const key = this.generateProjectKey(ProjectCacheKey.driveItems, projectId, this.encodeResource(resource), path);
        this.cache.registerIfNeeded(
          key,
          async () => {
            return await firstValueFrom(
              this.projectDriveClient.items(projectId, path, false, null, this.encodeResource(resource), filter).pipe(
                map(apiResult => ({
                  addFileAllowed: apiResult.metadata.addFileAllowed,
                  addFolderAllowed: apiResult.metadata.addFolderAllowed,
                  items: apiResult.content as DriveItemModel[],
                }))
              )
            );
          },
          AppConfigService.settings.api.driveItemCachingInMs
        );

        return await this.cache.get<DriveItemsResult>(key);
      },
      async () =>
        await this.offlineService.getDriveItems(
          projectId,
          path,
          false,
          searchText,
          this.encodeResource(resource),
          metadata,
          filter
        ),
      projectId
    );
  }

  async getRecentDriveItemFiles(count: number, resource: string): Promise<RecentFiles> {
    const cacheKey: string = this.generateProjectKey(ProjectCacheKey.recentDocuments, this.projectId, count.toString());
    this.cache.registerIfNeeded(
      cacheKey,
      async () => {
        const filter = new C4ApiFilterDefinition();
        filter.top = count; //aligned with UI design
        filter.totalCount = true;
        filter.orderBy = {
          createdDateTime: true,
        };
        const result = await this.projectDriveClient
          .items(this.projectId, '', true, null, this.encodeResource(resource), filter)
          .toPromise();
        return {
          //unkown: totalCount: result.metadata.totalItemCount,
          files: result.content as DriveItemModel[],
        };
      },
      AppConfigService.settings.api.driveItemCachingInMs
    );

    return await this.cache.get(cacheKey);
  }

  async createFolder(folderName: string, path: string, resource: string | undefined) {
    const projectId = this.projectId;
    await this.projectDriveClient.createFolder(projectId, folderName, path, this.encodeResource(resource)).toPromise();
    this.removeCachedDriveItems(projectId);
  }

  async uploadDriveItemFile(
    fileName: string,
    existingNameBehavior: ConflictBehavior,
    fileData: any,
    path: string,
    resource: string = null,
    metadata: DriveActionMetadata = null,
    progressCallback: (progressPercent: number) => void
  ) {
    const projectId = this.projectId;
    path = this.normalizeDrivePath(path);

    return await this.tryOfflineAsync(
      async () => {
        const file: FileParameter = <FileParameter>{
          fileName,
          data: fileData,
        };
        const result = await this.projectDriveClient.uploadWithProgress(
          projectId,
          file,
          path,
          this.encodeResource(resource),
          existingNameBehavior,
          metadata,
          progressCallback
        );
        this.removeCachedDriveItems(projectId);
        return result;
      },
      async () =>
        await this.offlineService.uploadDriveItem(
          projectId,
          fileName,
          fileData,
          path,
          existingNameBehavior,
          this.encodeResource(resource),
          metadata,
          progressCallback
        ),
      projectId
    );
  }

  async uploadDriveItemFileInChunks(
    fileName: string,
    fileSize: number,
    existingNameBehavior: ConflictBehavior,
    fileData: any,
    path: string,
    resource: string = null,
    metadata: DriveActionMetadata = null,
    progressCallback: (progressPercent: number) => void
  ): Promise<DriveUploadResultModel> {
    const projectId = this.projectId;
    path = this.normalizeDrivePath(path);

    return await this.tryOfflineAsync(
      async () => {
        const uploadSession = await firstValueFrom(
          this.projectDriveClient.startUpload(
            projectId,
            fileName,
            fileSize,
            path,
            existingNameBehavior,
            this.encodeResource(resource)
          )
        );

        let result: DriveUploadResultModel = null;
        try {
          const chunkCount = Math.ceil(fileSize / uploadSession.chunkSize);
          this.log.debug(`Created upload session ${uploadSession.uploadSessionId} with chunk size ${uploadSession.chunkSize}`);
          let processedFileSizeInBytes: number = 0;
          let lastUploadResult: DriveUploadChunkResultModel;

          for (let i = 0; i < chunkCount; i++) {
            this.log.debug(`Uploading to session ${uploadSession.uploadSessionId}: chunk ${i + 1} of ${chunkCount}`);
            const offset = i * uploadSession.chunkSize;
            const fragment: Blob = fileData.slice(offset, offset + uploadSession.chunkSize);
            const fragmentFile = {
              fileName,
              data: fragment,
            };
            const currentFileSizeInBytes: number = fragment.size;
            lastUploadResult = await this.projectDriveClient.uploadChunkWithProgress(
              uploadSession.uploadSessionId,
              projectId,
              offset,
              fragmentFile,
              this.encodeResource(resource),
              metadata,
              (progressPercent: number) => {
                if (progressCallback) {
                  progressCallback((processedFileSizeInBytes + currentFileSizeInBytes * progressPercent) / fileSize);
                }
              }
            );
            processedFileSizeInBytes += currentFileSizeInBytes;
            if (lastUploadResult && lastUploadResult.fileCompleted) {
              result = lastUploadResult;
            }
          }
          if (!lastUploadResult.fileCompleted) {
            throw new Error('File is not completed after uploading the last chunk');
          }
        } catch (e) {
          //in case of an error: delete created upload session
          this.projectDriveClient.deleteUploadSession(uploadSession.uploadSessionId, projectId);
          throw e;
        }

        this.removeCachedDriveItems(projectId);
        return result;
      },
      async () =>
        await this.offlineService.uploadDriveItem(
          projectId,
          fileName,
          fileData,
          path,
          existingNameBehavior,
          this.encodeResource(resource),
          metadata,
          progressCallback
        ),
      projectId
    );
  }

  async downloadDriveItem(id: string): Promise<Blob> {
    return (await this.projectDriveClient.download(id, this.projectId).toPromise()).data;
  }

  async generateDriveItemDownloadUrl(request: DownloadDriveItemsRequest): Promise<DriveGenerateDownloadUrlResultModel> {
    return await this.projectDriveClient.generateDownloadUrl(this.projectId, null, request).toPromise();
  }

  async markDriveItemAsRead(id: string, resource: string | undefined) {
    try {
      const projectId = this.projectId;
      await this.projectDriveClient.markFileAsRead(id, projectId, this.encodeResource(resource)).toPromise();
      this.removeCachedDriveItems(projectId);
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        if (e.status === 404) {
          //means that item does not exist any more or is already marked as read - ignore in UI
          return;
        }
      }
      throw e;
    }
  }

  async resendEmail(id: string) {
    const projectId = this.projectId;
    return await this.projectDriveClient.resendNotification(projectId, id).toPromise();
  }

  async deleteDriveItem(id: string, resource: string = null): Promise<void> {
    const projectId = this.projectId;
    await this.projectDriveClient.delete(id, projectId, this.encodeResource(resource)).toPromise();
    this.removeCachedDriveItems(projectId);
  }

  async renameDriveItem(id: string, newName: string, resource?: string | undefined) {
    const projectId = this.projectId;
    await this.projectDriveClient.rename(id, projectId, newName, this.encodeResource(resource)).toPromise();
    this.removeCachedDriveItems(projectId);
  }

  async changeReleaseState(id: string, newName: string, resource?: string | undefined) {
    const projectId = this.projectId;
    await this.projectDriveClient.changeReleaseState(id, projectId, newName, this.encodeResource(resource)).toPromise();
    this.removeCachedDriveItems(projectId);
  }

  async moveDriveItem(id: string, resource: string, moveModel: DriveItemMoveModel): Promise<DriveItemModel> {
    const projectId = this.projectId;
    const driveItem = await this.projectDriveClient.move(id, projectId, this.encodeResource(resource), moveModel).toPromise();
    this.removeCachedDriveItems(projectId);
    return driveItem;
  }

  async generateZipDownloadUrl(model: GenerateZipDownloadUrlModel): Promise<DriveGenerateDownloadUrlResultModel> {
    return await this.projectDriveClient.generateShareTransactionUrl(this.projectId, undefined, model).toPromise();
  }

  async generateAttachmentItemDownloadUrl(id: string): Promise<string> {
    return (await this.attachmentClient.generateAttachmentDownloadUrl(id).toPromise()).url;
  }

  async getChangeSet(id: string, sendType?: NotificationSendType): Promise<DriveItemChangeSetModel[]> {
    return await this.projectNotificationClient.driveItemChangeSet(id, this.projectId, sendType).toPromise();
  }

  async getPreview(id: string, spec: FilePreviewSpec, resource: string, projectId = this.projectId) {
    return await this.tryOfflineAsync(
      async () => {
        return await firstValueFrom(
          this.projectDriveClient.getPreviewContent(id, projectId, spec, this.encodeResource(resource))
        );
      },
      async () => await this.offlineService.getPreview(id, projectId, spec, this.encodeResource(resource)),
      projectId
    );
  }

  // #endregion

  // #region Document Signing

  async startATrustSignDocument(id: string, resource: string = ''): Promise<SignDocumentResultModel> {
    return await this.projectATrustSignClient.startSignDocument(this.projectId, id, this.encodeResource(resource)).toPromise();
  }

  async startDrawToSignDocument(id: string, resource: string = ''): Promise<SignDocumentResultModel> {
    return await this.projectDrawToSignClient.startSignDocument(this.projectId, id, this.encodeResource(resource)).toPromise();
  }

  async dataUrlDrawToSign(sessionId: string, model: DrawToSignDataModel, resource: string = ''): Promise<CallbackResponse> {
    return await this.projectDrawToSignClient
      .dataUrl(this.projectId, sessionId, this.encodeResource(resource), model)
      .toPromise();
  }

  // #endregion

  // #region Organizations

  async getOrganizations(): Promise<OrganizationModel[]> {
    return await this.organizationClient.get().toPromise();
  }

  async getUserForOrganization(organizationId: string): Promise<UserModel[]> {
    return await this.organizationClient.getUser(organizationId).toPromise();
  }

  async getUserForOrganizationInProject(organizationId: string, projectId = this.projectId): Promise<UserModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectOrganizationClient.getUsers(organizationId, this.projectId)),
      async () => await this.offlineService.getUserForOrganisationInProject(organizationId, projectId),
      projectId
    );
  }

  async getOrganizationsForProject(projectId = this.projectId): Promise<OrganizationModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectOrganizationClient.get(projectId)),
      async () => await this.offlineService.getOrganizationsForProject(projectId),
      projectId
    );
  }

  async saveOrganization(model: OrganizationModel): Promise<string> {
    return this.projectId
      ? await this.projectOrganizationClient.save(this.projectId, model).toPromise()
      : await this.organizationClient.save(model).toPromise();
  }

  async assignOrganization(model: AssignOrganizationModel) {
    await this.projectOrganizationClient.assign(this.projectId, model).toPromise();
  }

  async removeOrganization(id: string) {
    await this.organizationClient.remove(id).toPromise();
  }

  async removeOrganizationsFromProject(id: string, deleteProjectUsers: boolean) {
    await this.projectOrganizationClient.remove(id, this.projectId, deleteProjectUsers).toPromise();
  }

  async setOrganizationMainClient(id: string, isMainClient: boolean) {
    await this.projectOrganizationClient.setMainClient(id, this.projectId, isMainClient).toPromise();
  }

  // #endregion

  // #region Project Crafts & Organizations

  async getAllOrganizationCraftsFromProject(projectId: string) {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectOrganizationCraftClient.getAll(projectId)),
      async () => await this.offlineService.getCrafts(), // todo - check why project id not required
      projectId
    );
  }

  async getAllOrganizationsFromProject(projectId: string) {
    return await this.tryOfflineAsync(
      async () => firstValueFrom(this.projectOrganizationClient.getAll(projectId)),
      async () => await this.offlineService.getOrganizationsForProject(projectId),
      projectId
    );
  }

  // #endregion

  // #region Teams

  async getCrafts(): Promise<CraftModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.craftClient.get()),
      async () => this.offlineService.getCrafts()
    );
  }

  async saveCraft(model: CraftModel): Promise<string> {
    return await this.craftClient.save(model).toPromise();
  }

  async assignCraft(craftId: string, organizationId: string): Promise<string> {
    return await this.projectOrganizationCraftClient.assignCraft(organizationId, craftId, this.projectId).toPromise();
  }

  async deleteCraft(craftId: string) {
    return await this.craftClient.delete(craftId).toPromise();
  }

  async removeAssignedCraft(craftId: string, organizationId: string) {
    await this.projectOrganizationCraftClient.removeCraft(organizationId, craftId, this.projectId).toPromise();
  }

  // #endregion

  // #region Defects

  async createEmptyDefect(projectId: string = this.projectId): Promise<DefectModel> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectDefectClient.createEmpty(projectId)),
      async () => await this.offlineService.createEmptyDefect(projectId),
      projectId
    );
  }

  async getDefect(id: string, projectId = this.projectId): Promise<DefectModel> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectDefectClient.get(id, projectId)),
      async () => await this.offlineService.getDefect(id, projectId),
      projectId
    );
  }

  async getDefectsForProject(projectId = this.projectId): Promise<DefectModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectDefectClient.getAll(projectId)),
      async () => await this.offlineService.getDefectsForProject(projectId),
      projectId
    );
  }

  async saveDefect(model: AddOrUpdateDefectModel, files: FileParameter[] = [], projectId = this.projectId): Promise<string> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectDefectClient.save(projectId, model, files)),
      async () => await this.offlineService.saveDefect(projectId, model, files),
      projectId
    );
  }

  async shareDefect(model: ShareDefectModel): Promise<NotificationResultModel> {
    return await this.projectDefectClient.share(this.projectId, model).toPromise();
  }

  async editDefectComment(defectId: string, model: CommentModel, projectId = this.projectId) {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectDefectClient.updateComment(defectId, model.id, this.projectId, model)),
      async () => {
        await this.offlineService.editDefectComment(projectId, model);
        return model.id;
      },
      projectId
    );
  }

  async updateDefect(changeDefectModels: ChangeDefectModel) {
    return await this.projectDefectClient.updateDefect(this.projectId, changeDefectModels).toPromise();
  }

  async deleteDefectComment(defectId: string, commentId: string) {
    await this.projectDefectClient.deleteComment(defectId, commentId, this.projectId).toPromise();
  }

  specsGroundPlan(modelId: string) {
    return this.projectPlanClient.getSpecs(modelId, this.projectId);
  }

  getLatestOrQueueConversion(modelId: string) {
    return this.projectPlanClient.getLatestOrQueueConversion(modelId, this.projectId);
  }

  async getDefectTypes(): Promise<DefectTypeModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.defectTypeClient.get()),
      async () => await this.offlineService.getDefectTypes()
    );
  }

  async saveDefectType(model: DefectTypeModel): Promise<string> {
    return await this.defectTypeClient.save(model).toPromise();
  }

  async deleteDefectType(id: string) {
    await this.defectTypeClient.delete(id).toPromise();
  }

  async getDefectReasons(): Promise<DefectReasonModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.defectReasonClient.get()),
      async () => await this.offlineService.getDefectReasons()
    );
  }

  async saveDefectReason(model: DefectReasonModel): Promise<string> {
    return await this.defectReasonClient.save(model).toPromise();
  }

  async deleteDefectReason(id: string) {
    await this.defectReasonClient.delete(id).toPromise();
  }

  // #endregion

  // #region Construction Diaries

  async getDiariesForProject(): Promise<ConstructionDiaryModel[]> {
    return await this.projectDiaryClient.getAll(this.projectId).toPromise();
  }

  async createEmptyDiary(): Promise<ConstructionDiaryModel> {
    return await this.projectDiaryClient.createEmpty(this.projectId).toPromise();
  }

  async getDiary(diaryId: string): Promise<ConstructionDiaryModel> {
    return await this.projectDiaryClient.get(diaryId, this.projectId).toPromise();
  }

  async getDiaryConstructionInfo(): Promise<ConstructionDiaryCreationInfoModel> {
    return await this.projectDiaryClient.getCreationInfo(this.projectId).toPromise();
  }

  async saveDiary(model: AddOrUpdateConstructionDiaryModel, files: FileParameter[] = []): Promise<string> {
    return await this.projectDiaryClient.save(this.projectId, model, files).toPromise();
  }

  async getRemarkTypes(): Promise<CodeModel[]> {
    return await this.remarkTypeClient.getRemarkTypes().toPromise();
  }

  async getHistoricalWeatherDataForProject(d: Moment): Promise<DailyWeatherModel> {
    const now = moment();
    if (now < d) return null;

    const nDaysBefore = now.startOf('day').diff(d.startOf('day'), 'days');
    return await this.weatherClient.getHistoricalWeatherData(this.projectId, nDaysBefore).toPromise();
  }

  // #endregion

  // #region RoomBook

  async getAreasForProject(projectId = this.projectId): Promise<AreaModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectRoomBookClient.getAreas(projectId)),
      async () => await this.offlineService.getAreasForProject(projectId),
      projectId
    );
  }

  async getArea(areaId: string): Promise<AreaModel> {
    return await this.projectRoomBookClient.getArea(areaId, this.projectId).toPromise();
  }

  async saveArea(model: AreaModel, force: boolean = false): Promise<string> {
    return await this.projectRoomBookClient.saveArea(this.projectId, force, model).toPromise();
  }

  async deleteArea(areaId: string) {
    await this.projectRoomBookClient.deleteArea(areaId, this.projectId).toPromise();
  }

  async getZoneGroupsForProject(projectId = this.projectId): Promise<ZoneGroupModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.zoneGroupClient.getAll(projectId, true)),
      async () => await this.offlineService.getZoneGroupsForProject(projectId),
      projectId
    );
  }

  async getZoneGroup(zoneGroupId: string): Promise<ZoneGroupModel> {
    return await this.zoneGroupClient.get(zoneGroupId, this.projectId, false).toPromise();
  }

  async saveZoneGroup(model: ZoneGroupModel): Promise<string> {
    return await this.zoneGroupClient.save(this.projectId, model).toPromise();
  }

  async deleteZoneGroup(zoneGroupId: string) {
    await this.zoneGroupClient.delete(zoneGroupId, this.projectId).toPromise();
  }

  async getZonesForProject(projectId = this.projectId): Promise<ZoneModel[]> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.zoneClient.getAll(projectId)),
      async () => await this.offlineService.getZonesForProject(projectId),
      projectId
    );
  }

  async getZone(zoneId: string): Promise<ZoneModel> {
    return await this.zoneClient.get(zoneId, this.projectId).toPromise();
  }

  async saveZone(model: ZoneModel): Promise<string> {
    if (model.id === undefined) return await this.zoneClient.createZone(this.projectId, model).toPromise();
    return await this.zoneClient.updateZone(model.id, this.projectId, model).toPromise();
  }

  async deleteZone(zoneId: string) {
    await this.zoneClient.delete(zoneId, this.projectId).toPromise();
  }

  async getRegion(regionId: string): Promise<RegionModel> {
    return await this.projectRoomBookClient.getRegion(regionId, this.projectId).toPromise();
  }

  async getRegionsForArea(areaId: string): Promise<RegionModel[]> {
    return await this.projectRoomBookClient.getRegionsForArea(areaId, this.projectId).toPromise();
  }

  async saveRegion(model: RegionModel): Promise<string> {
    return await this.projectRoomBookClient.saveRegion(this.projectId, model).toPromise();
  }

  async deleteRegion(regionId: string) {
    await this.projectRoomBookClient.deleteRegion(regionId, this.projectId).toPromise();
  }

  async getFloor(floorId: string): Promise<FloorModel> {
    return await this.projectRoomBookClient.getFloor(floorId, this.projectId).toPromise();
  }

  async saveFloor(model: FloorModel, force: boolean = false): Promise<string> {
    return await this.projectRoomBookClient.saveFloor(this.projectId, force, model).toPromise();
  }

  async deleteFloor(floorId: string) {
    await this.projectRoomBookClient.deleteFloor(floorId, this.projectId).toPromise();
  }

  async getRoom(roomId: string, projectId = this.projectId): Promise<RoomModel> {
    return await this.tryOfflineAsync(
      async () => await firstValueFrom(this.projectRoomBookClient.getRoom(roomId, projectId)),
      async () => await this.offlineService.getRoomById(roomId, projectId),
      projectId
    );
  }

  async updateRoomSequence(roomId: string, model: SequenceModel) {
    return await this.projectRoomBookClient.updateSequence(roomId, this.projectId, model).toPromise();
  }

  async getRoomsForProject(): Promise<RoomModel[]> {
    return await this.projectRoomBookClient.getRooms(this.projectId).toPromise();
  }

  async saveRoom(model: RoomModel, files: FileParameter[] = [], force: boolean = false): Promise<string> {
    return await this.projectRoomBookClient.saveRoom(this.projectId, force, model, files).toPromise();
  }

  async createRoomFromTemplate(roomId: string, templateName: string): Promise<string> {
    return await this.projectRoomBookClient.createTemplateFromRoom(roomId, this.projectId, templateName).toPromise();
  }

  async deleteRoom(id: string) {
    await this.projectRoomBookClient.deleteRoom(id, this.projectId).toPromise();
  }

  async editRoomComment(roomId: string, model: CommentModel) {
    return await this.projectRoomBookClient.updateComment(roomId, model.id, this.projectId, model).toPromise();
  }

  async deleteRoomComment(roomId: string, commentId: string) {
    await this.projectRoomBookClient.deleteComment(roomId, commentId, this.projectId).toPromise();
  }

  async getRoomTemplate(id: string): Promise<RoomTemplateModel> {
    return this.projectId
      ? await this.projectRoomTemplateClient.get(id, this.projectId).toPromise()
      : await this.roomTemplateClient.get(id).toPromise();
  }

  async getRoomTemplates(): Promise<RoomTemplateModel[]> {
    return this.projectId
      ? await this.projectRoomTemplateClient.getAll(this.projectId).toPromise()
      : await this.roomTemplateClient.getAll().toPromise();
  }

  async getGlobalRoomTemplates(): Promise<RoomTemplateModel[]> {
    return this.projectId
      ? await this.projectRoomTemplateClient.getGlobal(this.projectId).toPromise()
      : await this.roomTemplateClient.getAll().toPromise();
  }

  async saveRoomTemplate(model: RoomTemplateModel): Promise<string> {
    return this.projectId
      ? await this.projectRoomTemplateClient.save(this.projectId, model).toPromise()
      : await this.roomTemplateClient.save(model).toPromise();
  }

  async removeRoomTemplate(id: string): Promise<void> {
    return this.projectId
      ? await this.projectRoomTemplateClient.delete(id, this.projectId).toPromise()
      : await this.roomTemplateClient.delete(id).toPromise();
  }

  async getAttribute(id: string): Promise<AttributeModel> {
    return this.projectId
      ? await this.projectRoomAttributeClient.get(id, this.projectId).toPromise()
      : await this.roomAttributeClient.get(id).toPromise();
  }

  async getAttributes(): Promise<AttributeModel[]> {
    return this.projectId
      ? await this.projectRoomAttributeClient.getAll(this.projectId).toPromise()
      : await this.roomAttributeClient.getAll().toPromise();
  }

  async getGlobalAttributes(): Promise<AttributeModel[]> {
    return this.projectId
      ? await this.projectRoomAttributeClient.getGlobal(this.projectId).toPromise()
      : await this.roomAttributeClient.getAll().toPromise();
  }

  async saveAttribute(model: AttributeModel): Promise<string> {
    return this.projectId
      ? await this.projectRoomAttributeClient.save(this.projectId, model).toPromise()
      : await this.roomAttributeClient.save(model).toPromise();
  }

  async deleteAttribute(id: string): Promise<void> {
    return this.projectId
      ? await this.projectRoomAttributeClient.delete(id, this.projectId).toPromise()
      : await this.roomAttributeClient.delete(id).toPromise();
  }

  async getCategory(id: string): Promise<CategoryModel | ExtendedCategoryModel> {
    return this.projectId
      ? await this.projectRoomCategoryClient.get(id, this.projectId).toPromise()
      : await this.roomCategoryClient.get(id).toPromise();
  }

  async getCategories(structure: StructureType, maxDepth: number = -1): Promise<CategoryModel[] | ExtendedCategoryModel[]> {
    let categories = this.projectId
      ? await this.projectRoomCategoryClient.getAll(this.projectId, structure == StructureType.flat, maxDepth).toPromise()
      : await this.roomCategoryClient.getAll(structure == StructureType.flat, maxDepth).toPromise();

    if (structure == StructureType.treeWithParents) {
      this.linkCategoryParents(categories);
    }

    return categories;
  }

  async getGlobalCategories(structure: StructureType): Promise<CategoryModel[] | ExtendedCategoryModel[]> {
    let categories = this.projectId
      ? await this.projectRoomCategoryClient.getGlobal(this.projectId, structure == StructureType.flat, -1).toPromise()
      : await this.roomCategoryClient.getAll(structure == StructureType.flat, -1).toPromise();

    if (structure == StructureType.treeWithParents) {
      this.linkCategoryParents(categories);
    }

    return categories;
  }

  async saveCategory(model: CategoryModel): Promise<string> {
    return this.projectId
      ? await this.projectRoomCategoryClient.save(this.projectId, model).toPromise()
      : await this.roomCategoryClient.save(model).toPromise();
  }

  async deleteCategory(id: string): Promise<void> {
    return this.projectId
      ? await this.projectRoomCategoryClient.delete(id, this.projectId).toPromise()
      : await this.roomCategoryClient.delete(id).toPromise();
  }

  async transferRoomTemplatesToProject(roomTemplateIds: string[]): Promise<void> {
    return await this.projectRoomTemplateClient.transferToProject(this.projectId, roomTemplateIds).toPromise();
  }

  async transferAttributesToProject(attributeIds: string[]): Promise<void> {
    return await this.projectRoomAttributeClient.transferToProject(this.projectId, attributeIds).toPromise();
  }

  async transferCategoriesToProject(categoryIds: string[]): Promise<void> {
    return await this.projectRoomCategoryClient.transferToProject(this.projectId, categoryIds).toPromise();
  }

  async exportRoomBookFromProject(
    content: RmFileContent,
    format: TransferFileFormat,
    ids: string[] = []
  ): Promise<FileResponse> {
    return await this.projectRoomBookClient.export(this.projectId, content, format, ids).toPromise();
  }

  async importRoomBookToProject(content: RmFileContent, format: TransferFileFormat, file: FileParameter) {
    return await this.projectRoomBookClient.import(this.projectId, content, format, file).toPromise();
  }

  // #endregion

  // #region Gallery

  async getGalleryItems(resource: string = null): Promise<GalleryDriveItemModel[]> {
    return await this.projectGalleryClient.getGalleryItems(this.projectId, this.encodeResource(resource)).toPromise();
  }

  async getGalleryItem(id: string, resource: string = null): Promise<GalleryDriveItemModel> {
    return await this.projectGalleryClient.getGalleryItem(id, this.projectId, this.encodeResource(resource)).toPromise();
  }

  async updateTags(model: DriveItemTagsUpdateModel, resource: string = null) {
    return await this.projectGalleryClient.updateTags(this.projectId, this.encodeResource(resource), model).toPromise();
  }

  async selectTags(resource: string = null) {
    return await this.projectGalleryClient.getTags(this.projectId, this.encodeResource(resource)).toPromise();
  }

  // #endregion

  // #region Reports

  async reportForDefects(model: GenerateReportModel): Promise<LRTaskStatus> {
    const projectId = this.projectId;
    const lrTask = await this.projectReportClient.getReportForDefects(this.projectId, model).toPromise();
    this.removeCachedDriveItems(projectId);
    return lrTask;
  }

  async reportQualityWalkForDefects(model: GenerateReportModel): Promise<LRTaskStatus> {
    const projectId = this.projectId;
    const lrTask = await this.projectReportClient.getReportForQualityWalk(this.projectId, model).toPromise();
    this.removeCachedDriveItems(projectId);
    return lrTask;
  }

  async reportForDiaries(model: GenerateReportModel): Promise<LRTaskStatus> {
    const projectId = this.projectId;
    const lrTask = await this.projectReportClient.getReportForConstructionDiary(this.projectId, model).toPromise();
    this.removeCachedDriveItems(projectId);
    return lrTask;
  }

  async reportForRooms(roomIds: string[]): Promise<FileResponse> {
    return await this.projectReportClient.getReportForRooms(this.projectId, roomIds).toPromise();
  }

  async reportForDynamicRoom(model: GenerateReportModel): Promise<LRTaskStatus> {
    const projectId = this.projectId;
    const lrTask = await this.projectReportClient.getReportForDynamicRoom(this.projectId, model).toPromise();
    this.removeCachedDriveItems(projectId);
    return lrTask;
  }

  async reportForDriveFolder(path: string, resource: string) {
    return await this.projectDriveClient.getReportSnapshot(this.projectId, path, this.encodeResource(resource)).toPromise();
  }

  // #endregion

  // #region StandardTests

  async getStandardTexts(): Promise<StandardTextModel[]> {
    return await this.standardTextClient.getStandardTexts().toPromise();
  }

  async saveStandardText(model: StandardTextModel): Promise<string> {
    return await this.standardTextClient.saveStandardText(model).toPromise();
  }

  async deleteStandardText(id: string) {
    await this.standardTextClient.deleteStandardText(id).toPromise();
  }

  // #endregion

  // #region Issues (BIM)

  async getXeokitProjects(): Promise<XeokitProjectsModel> {
    return await this.xeokitDataClient.getProjects().toPromise();
  }

  async getXeokitProject(id: string): Promise<XeokitProjectModel> {
    return await this.xeokitDataClient.getProject(id).toPromise();
  }

  async getGeometry(projectId: string, modelId: string): Promise<FileResponse> {
    return await this.xeokitDataClient.getGeometry(projectId, modelId).toPromise();
  }

  async convertModel(modelId: string, fileExtension: string): Promise<LRTaskStatus> {
    return await this.xeokitDataClient.convertToXKT(this.projectId, modelId, fileExtension).toPromise();
  }

  async getConvertableFileTypes(): Promise<string[]> {
    return await this.xeokitDataClient.getConvertableFileTypes(this.projectId).toPromise();
  }

  // async getIssuePrivileges(): Promise<IssuePrivilegeModel> {
  //   return await this.issueClient.getIssuePrivileges().toPromise();
  // }

  async getIssuesForProject(xktModelIds: string[] = []): Promise<IssueModel[]> {
    return await this.projectIssueClient.getAll(this.projectId, xktModelIds).toPromise();
  }

  async getIssueForProject(issueId: string): Promise<IssueModel> {
    return await this.projectIssueClient.get(issueId, this.projectId).toPromise();
  }

  async createEmptyIssue(): Promise<IssueModel> {
    return await this.projectIssueClient.createEmpty(this.projectId).toPromise();
  }

  async getIssue(id: string): Promise<IssueModel> {
    return await this.projectIssueClient.get(id, this.projectId).toPromise();
  }

  async saveIssue(model: AddOrUpdateIssueModel, files: File[] = []): Promise<string> {
    return await this.projectIssueClient
      .save(
        this.projectId,
        model,
        files.map(f => ({ fileName: f.name, data: f }))
      )
      .toPromise();
  }

  async updateIssues(models: AddOrUpdateIssueModel[]) {
    await this.projectIssueClient
      .updateIssues(
        this.projectId,
        new IssueModels({
          items: models,
        })
      )
      .toPromise();
  }

  async importIssues(xktIds: string[], file: File): Promise<string> {
    this.cache.remove(AppCacheKey.issueLabels);
    this.cache.remove(AppCacheKey.issueTypes);
    this.cache.remove(AppCacheKey.issueStages);

    return await this.projectIssueClient
      .importBcf(this.projectId, new XktListModel({ xktIds }), {
        fileName: file.name,
        data: file,
      })
      .toPromise();
  }

  async exportIssues(issueIds: string[]): Promise<FileResponse> {
    return await this.projectIssueClient.exportBcf(this.projectId, issueIds).toPromise();
  }

  async getIssueLabels(): Promise<BcfCodeModel[]> {
    this.cache.registerIfNeeded(AppCacheKey.issueLabels, async () => {
      return await this.issueLabelClient.get().toPromise();
    });
    return await this.cache.get(AppCacheKey.issueLabels);
  }

  async saveIssueLabel(model: BcfCodeModel): Promise<string> {
    this.cache.remove(AppCacheKey.issueLabels);
    return await this.issueLabelClient.save(model).toPromise();
  }

  async deleteIssueLabel(id: string) {
    this.cache.remove(AppCacheKey.issueLabels);
    await this.issueLabelClient.delete(id).toPromise();
  }

  async getIssueTypes(): Promise<BcfCodeModel[]> {
    this.cache.registerIfNeeded(AppCacheKey.issueTypes, async () => {
      return await this.issueTypeClient.get().toPromise();
    });
    return await this.cache.get(AppCacheKey.issueTypes);
  }

  async saveIssueType(model: BcfCodeModel): Promise<string> {
    this.cache.remove(AppCacheKey.issueTypes);
    return await this.issueTypeClient.save(model).toPromise();
  }

  async deleteIssueType(id: string) {
    this.cache.remove(AppCacheKey.issueTypes);
    await this.issueTypeClient.delete(id).toPromise();
  }

  async getIssueStages(): Promise<BcfCodeModel[]> {
    this.cache.registerIfNeeded(AppCacheKey.issueStages, async () => {
      return await this.issueStageClient.get().toPromise();
    });
    return await this.cache.get(AppCacheKey.issueStages);
  }

  async saveIssueStage(model: BcfCodeModel): Promise<string> {
    this.cache.remove(AppCacheKey.issueStages);
    return await this.issueStageClient.save(model).toPromise();
  }

  async deleteIssueStage(id: string) {
    this.cache.remove(AppCacheKey.issueStages);
    await this.issueStageClient.delete(id).toPromise();
  }

  getIssueComments(issueId: string) {
    return this.projectIssueClient.getComments(issueId, this.projectId);
  }

  async addIssueComment(issueId: string, model: AddOrUpdateIssueCommentModel) {
    return await this.projectIssueClient.addComment(issueId, this.projectId, model).toPromise();
  }

  async editIssueComment(issueId: string, model: AddOrUpdateIssueCommentModel) {
    return await this.projectIssueClient.updateComment(issueId, model.id, this.projectId, model).toPromise();
  }

  async deleteIssueComment(issueId: string, commentId: string) {
    await this.projectIssueClient.deleteComment(issueId, commentId, this.projectId).toPromise();
  }

  async shareIssue(model: ShareEntityModel): Promise<void> {
    await this.projectIssueClient.share(this.projectId, model).toPromise();
  }

  async convertSignedUrl(url: string, conversionType: SignedUrlConversionType): Promise<SignedUrlResultModel> {
    return await this.legacyClient.convertSignedUrl(conversionType, url).toPromise();
  }

  async downloadFile(taskId: string): Promise<FileResponse> {
    return await this.lrtTaskClient.downloadFile(taskId).toPromise();
  }

  async addViewpointToIssue(issueId: string, model: IBcfViewpointModel) {
    return await this.projectIssueClient
      .addViewpoint(
        issueId,
        this.projectId,
        new AddIssueViewpointModel({
          bcfViewpoint: BcfViewpointModel.fromJS(model),
          type: BcfVersion.BCFApi30,
        })
      )
      .toPromise();
  }

  getViewpointOfIssue(issueId: string, viewpointId: string) {
    return this.projectIssueClient.getViewPointById(issueId, viewpointId, this.projectId);
  }

  getViewpoints(issueId: string) {
    return this.projectIssueClient.getViewPointsForIssue(issueId, this.projectId);
  }

  async deleteViewpointFromIssue(issueId: string, viewpointId: string): Promise<void> {
    await this.projectIssueClient.deleteViewpointById(issueId, viewpointId, this.projectId).toPromise();
  }

  // #endregion

  // #region Bookmarks (BIM)

  async getBookmarks() {
    return await this.bimProjectBookmarkClient.select(this.projectId).toPromise();
  }

  async updateBookmark(id: string, title: string, description: string): Promise<BimBookmarkModel> {
    var result = new BimBookmarkCoreModel({
      title: title,
      description: description,
    });

    return await this.bimProjectBookmarkClient.update(id, this.projectId, result).toPromise();
  }

  async createBookmark(bookmark: BimBookmarkCreateModel): Promise<BimBookmarkModel> {
    return await this.bimProjectBookmarkClient.create(this.projectId, bookmark).toPromise();
  }

  async removeBookmark(bookmarkId: string) {
    await this.bimProjectBookmarkClient.delete(bookmarkId, this.projectId).toPromise();
  }

  // #endregion

  // #region Lean

  async syncBryntum(model: LeanBryntumSyncModel): Promise<LeanBryntumSyncResponseModel> {
    const returnValue = this.projectId
      ? await this.leanProjectClient.sync(this.projectId, model).toPromise()
      : await this.leanTenantClient.sync(model).toPromise();

    return returnValue;
  }

  async getPhases(expand: boolean = true): Promise<LeanPhaseModel[]> {
    const returnValue = this.projectId
      ? await this.leanProjectPhasesClient.getPhases(this.projectId, expand).toPromise()
      : await this.leanPhasesClient.getPhases(expand).toPromise();

    return returnValue.items;
  }

  async getGlobalPhases(expand: boolean = true): Promise<LeanPhaseModel[]> {
    const returnValue = await this.leanPhasesClient.getPhases(expand).toPromise();
    return returnValue.items;
  }

  async savePhase(phase: LeanPhaseModel): Promise<string> {
    const projectId = this.projectId;
    const phaseId = phase.id;

    let newId: string = null;
    if (!phaseId) {
      const created = projectId
        ? await this.leanProjectPhasesClient.createPhase(projectId, phase).toPromise()
        : await this.leanPhasesClient.createPhase(phase).toPromise();

      newId = created.id;
    } else {
      if (projectId) await this.leanProjectPhasesClient.updatePhase(phaseId, projectId, phase).toPromise();
      else await this.leanPhasesClient.updatePhase(phaseId, phase).toPromise();
    }

    return newId ?? phaseId;
  }

  async transferPhasesToProject(phaseIds: string[]): Promise<LeanPhaseModel[]> {
    const returnValue = await this.leanProjectPhasesClient
      .import(
        this.projectId,
        new LeanProjectImport({
          autoInclude: false,
          phaseIds,
        })
      )
      .toPromise();

    return returnValue.items;
  }

  async removePhase(phaseId: string): Promise<void> {
    return this.projectId
      ? this.leanProjectPhasesClient.deletePhase(phaseId, this.projectId).toPromise()
      : this.leanPhasesClient.deletePhase(phaseId).toPromise();
  }

  async getSwimlanes(): Promise<LeanSwimlaneModel[]> {
    const returnValue = this.projectId
      ? await this.leanProjectSwimlaneClient.getSwimlanes(this.projectId).toPromise()
      : await this.leanSwimlaneClient.getSwimlanes().toPromise();

    return returnValue.items;
  }

  async getSwimlane(id: string): Promise<LeanSwimlaneModel> {
    return this.projectId
      ? await this.leanProjectSwimlaneClient.getSwimlane(id, this.projectId).toPromise()
      : await this.leanSwimlaneClient.getSwimlane(id).toPromise();
  }

  async getGlobalSwimlanes(): Promise<LeanSwimlaneModel[]> {
    const returnValue = await this.leanSwimlaneClient.getSwimlanes().toPromise();
    return returnValue.items;
  }

  async saveSwimlane(swimlane: LeanSwimlaneModel): Promise<string> {
    const projectId = this.projectId;
    const swimlaneId = swimlane.id;

    let newId: string = null;
    if (!swimlaneId) {
      const created = projectId
        ? await this.leanProjectSwimlaneClient.createSwimlane(projectId, swimlane).toPromise()
        : await this.leanSwimlaneClient.createSwimlane(swimlane).toPromise();

      newId = created.id;
    } else {
      if (projectId) await this.leanProjectSwimlaneClient.updateSwimlane(swimlaneId, projectId, swimlane).toPromise();
      else await this.leanSwimlaneClient.updateSwimlane(swimlaneId, swimlane).toPromise();
    }

    return newId ?? swimlaneId;
  }

  async transferSwimlanesToProject(swimlaneIds: string[]): Promise<LeanSwimlaneModel[]> {
    const returnValue = await this.leanProjectSwimlaneClient
      .import(
        this.projectId,
        new LeanProjectImport({
          autoInclude: true,
          swimlaneIds,
        })
      )
      .toPromise();

    return returnValue.items;
  }

  async removeSwimlane(swimlaneId: string): Promise<void> {
    return this.projectId
      ? this.leanProjectSwimlaneClient.deleteSwimlane(swimlaneId, this.projectId).toPromise()
      : this.leanSwimlaneClient.deleteSwimlane(swimlaneId).toPromise();
  }

  async getSpecialDates(): Promise<LeanSpecialDateModel[]> {
    const result = await this.leanProjectSpecialDatesClient.getSpecialDates(this.projectId).toPromise();
    return result.items;
  }

  async saveSpecialDate(date: LeanSpecialDateModel): Promise<string> {
    const dateId = date.id;

    let newId: string = null;
    if (!dateId) {
      const created = await this.leanProjectSpecialDatesClient.createSpecialDate(this.projectId, date).toPromise();
      newId = created.id;
    } else {
      await this.leanProjectSpecialDatesClient.updateSpecialDate(dateId, this.projectId, date).toPromise();
    }

    return newId ?? dateId;
  }

  async removeSpecialDate(dateId: string): Promise<void> {
    return await this.leanProjectSpecialDatesClient.deleteSpecialDate(dateId, this.projectId).toPromise();
  }

  async getWorkpackageTemplates(): Promise<LeanWorkpackageTemplateModel[]> {
    const returnValue = this.projectId
      ? await this.leanProjectWorkpackageTemplateClient.getWorkpackageTemplates(this.projectId).toPromise()
      : await this.leanWorkpackageTemplateClient.getWorkpackageTemplates().toPromise();

    return returnValue.items;
  }

  async getWorkpackageTemplate(id: string): Promise<LeanWorkpackageTemplateModel> {
    return this.projectId
      ? await this.leanProjectWorkpackageTemplateClient.getWorkpackageTemplate(id, this.projectId).toPromise()
      : await this.leanWorkpackageTemplateClient.getWorkpackageTemplate(id).toPromise();
  }

  async getGlobalTemplates(): Promise<LeanWorkpackageTemplateModel[]> {
    const returnValue = await this.leanWorkpackageTemplateClient.getWorkpackageTemplates().toPromise();
    return returnValue.items;
  }

  async transferWorkpackageTemplatesToProject(
    workpackageTemplateIds: string[],
    updateIfExisting: boolean
  ): Promise<LeanWorkpackageTemplateModel[]> {
    const returnValue = await this.leanProjectWorkpackageTemplateClient
      .import(
        this.projectId,
        new LeanProjectImport({
          autoInclude: true,
          workpackageTemplateIds,
          updateIfExisting: updateIfExisting,
        })
      )
      .toPromise();

    return returnValue.items;
  }

  async saveWorkpackageTemplate(model: LeanWorkpackageTemplateModel): Promise<string> {
    let workpackageTemplateId = model.id;

    if (!workpackageTemplateId) {
      const created = this.projectId
        ? await this.leanProjectWorkpackageTemplateClient.createWorkpackageTemplate(this.projectId, model).toPromise()
        : await this.leanWorkpackageTemplateClient.createWorkpackageTemplate(model).toPromise();

      workpackageTemplateId = created.id;
    } else {
      this.projectId
        ? await this.leanProjectWorkpackageTemplateClient.updateWorkpackageTemplate(model.id, this.projectId, model).toPromise()
        : await this.leanWorkpackageTemplateClient.updateWorkpackageTemplate(model.id, model).toPromise();
    }

    return workpackageTemplateId;
  }

  async deleteWorkpackageTemplate(id: string): Promise<void> {
    return this.projectId
      ? await this.leanProjectWorkpackageTemplateClient.deleteWorkpackageTemplate(id, this.projectId).toPromise()
      : await this.leanWorkpackageTemplateClient.deleteWorkpackageTemplate(id).toPromise();
  }

  async getSequence(id: string): Promise<LeanWorkpackageSequenceModel> {
    return this.projectId
      ? await this.leanProjectWorkpackageSequenceClient.getWorkpackageSequence(id, this.projectId).toPromise()
      : await this.leanWorkpackageSequenceClient.getWorkpackageSequence(id).toPromise();
  }

  async getSequences(): Promise<LeanWorkpackageSequenceModel[]> {
    const returnValue = this.projectId
      ? await this.leanProjectWorkpackageSequenceClient.getWorkpackageSequences(this.projectId).toPromise()
      : await this.leanWorkpackageSequenceClient.getWorkpackageSequences().toPromise();

    return returnValue.items;
  }

  async getGlobalSequences(): Promise<LeanWorkpackageSequenceModel[]> {
    const returnValue = await this.leanWorkpackageSequenceClient.getWorkpackageSequences().toPromise();
    return returnValue.items;
  }

  async transferSequencesToProject(sequenceIds: string[]): Promise<LeanWorkpackageSequenceModel[]> {
    const returnValue = await this.leanProjectWorkpackageSequenceClient
      .import(
        this.projectId,
        new LeanProjectImport({
          autoInclude: true,
          updateIfExisting: true,
          workpackageSequenceIds: sequenceIds,
        })
      )
      .toPromise();

    return returnValue.items;
  }

  async bulkSaveWorkpackageSequences(models: LeanWorkpackageSequenceModel[]): Promise<void> {
    if (this.projectId) await this.leanProjectWorkpackageSequenceClient.bulkSaveSequences(this.projectId, models).toPromise();
    else await this.leanWorkpackageSequenceClient.bulkSaveSequences(models).toPromise();
  }

  async saveWorkpackageSequence(model: LeanWorkpackageSequenceModel): Promise<string> {
    let sequenceId = model.id;

    if (!sequenceId) {
      const created = this.projectId
        ? await this.leanProjectWorkpackageSequenceClient.createSequence(this.projectId, model).toPromise()
        : await this.leanWorkpackageSequenceClient.createWorkpackageSequence(model).toPromise();

      sequenceId = created.id;
    } else {
      this.projectId
        ? await this.leanProjectWorkpackageSequenceClient.updateSequence(model.id, this.projectId, model).toPromise()
        : await this.leanWorkpackageSequenceClient.updateWorkpackageSequence(model.id, model).toPromise();
    }

    return sequenceId;
  }

  async deleteSequence(id: string): Promise<void> {
    return this.projectId
      ? await this.leanProjectWorkpackageSequenceClient.deleteSequence(id, this.projectId).toPromise()
      : await this.leanWorkpackageSequenceClient.deleteWorkpackageSequence(id).toPromise();
  }

  async getWorkpackages(): Promise<LeanWorkpackageModel[]> {
    const result = await this.leanProjectWorkpackageClient.getWorkpackages(this.projectId).toPromise();
    return result.items;
  }

  async getWorkpackage(id: string): Promise<LeanWorkpackageModel> {
    return await this.leanProjectWorkpackageClient.getWorkpackage(id, this.projectId).toPromise();
  }

  async getDistinctWorkpackages(): Promise<LeanWorkpackageModel[]> {
    const result = await this.leanProjectWorkpackageClient.getDistinctWorkpackages(this.projectId).toPromise();
    return result.items;
  }

  async getWorkpackageDependencies(workpackageId: string): Promise<LeanWorkpackageModel[]> {
    return await this.leanProjectWorkpackageClient.getWorkpackageDependencies(workpackageId, this.projectId).toPromise();
  }

  async saveWorkpackage(model: LeanWorkpackageModel): Promise<string> {
    let workpackageId = model.id;

    if (!workpackageId) {
      const created = await this.leanProjectWorkpackageClient.createWorkpackage(this.projectId, model).toPromise();
      workpackageId = created.id;
    } else {
      await this.leanProjectWorkpackageClient.updateWorkpackage(model.id, this.projectId, model).toPromise();
    }

    return workpackageId;
  }

  async updateWorkpackages(models: LeanWorkpackageModel[]) {
    return await this.leanProjectWorkpackageClient
      .updateWorkpackages(this.projectId, new LeanWorkpackagesModel({ items: models }))
      .toPromise();
  }

  async deleteWorkpackage(id: string): Promise<void> {
    return await this.leanProjectWorkpackageClient.deleteWorkpackage(id, this.projectId).toPromise();
  }

  getWorkpackageComments(id: string) {
    return this.leanProjectWorkpackageClient.getComments(id, this.projectId);
  }

  async addWorkpackageComment(workpackageId: string, model: CommentModel): Promise<string> {
    return await this.leanProjectWorkpackageClient.addComment(workpackageId, this.projectId, model).toPromise();
  }

  async editWorkpackageComment(workpackageId: string, model: CommentModel): Promise<string> {
    return await this.leanProjectWorkpackageClient.updateComment(workpackageId, model.id, this.projectId, model).toPromise();
  }

  async deleteWorkpackageComment(workpackageId: string, commentId: string): Promise<void> {
    return await this.leanProjectWorkpackageClient.deleteComment(workpackageId, commentId, this.projectId).toPromise();
  }

  getSimulationPlans(req: LeanSimulationPlansRequest) {
    return this.projectLeanSimulationClient.getPlans(this.projectId, req);
  }

  async getSimulationPlanFeatures(req: LeanSimulationFeaturesRequest) {
    return await this.projectLeanSimulationClient
      .getFeatures(this.projectId, req)
      .pipe(map(m => m.featureCollection))
      .toPromise();
  }

  async moveSimulationElements(req: LeanSimulationMoveElementsRequest) {
    return await this.projectLeanSimulationClient.postMoveElements(this.projectId, req).toPromise();
  }

  test(req: LeanSimulationPlansRequest) {
    return this.projectLeanSimulationClient.getPlans(this.projectId, req);
  }

  findPlan(workpackageId?: string) {
    const find = new LeanSimulationFindPlanRequest({
      workpackageId: workpackageId,
    });

    return this.projectLeanSimulationClient.postFindPlan(this.projectId, find);
  }

  async simulationSetSnapshot(driveItemMetadataId: string, year: number, week: number, a: SnapshotAreaModel) {
    return await this.projectLeanSimulationClient
      .getSnapshotAreaPOST(driveItemMetadataId, year, week, this.projectId, a)
      .toPromise();
  }

  async simulationRemoveSnapshot(driveItemMetadataId: string, year: number, week: number) {
    return await this.projectLeanSimulationClient
      .removeSnapshotArea(driveItemMetadataId, year, week, this.projectId)
      .toPromise();
  }

  async simulationGetSnapshot(driveItemMetadataId: string, year: number, week: number) {
    return await this.projectLeanSimulationClient
      .getSnapshotAreaGET(driveItemMetadataId, year, week, this.projectId)
      .toPromise();
  }

  // #endregion

  // #region Long Running Tasks

  getTaskStatusObservable(id: string) {
    return this.tasksClient.getStatus(id);
  }

  async getTaskStatus(id: string) {
    return await this.tasksClient.getStatus(id).toPromise();
  }

  // #endregion

  // #region OAuth

  async getCode(clientId: string): Promise<string> {
    return await this.oauthClient.getCode(clientId).toPromise();
  }

  public removeCachedDriveItems(projectId: string) {
    this.cache.removeWhereKeyStartsWith(this.generateProjectKey(ProjectCacheKey.driveItems, projectId));
    this.cache.removeWhereKeyStartsWith(this.generateProjectKey(ProjectCacheKey.recentDocuments, projectId));
  }
  // #endregion

  // #region Private Methods

  private async useOfflineData(projectId: string, useOfflineDataWhenSynced: boolean) {
    if (this.offlineService.isOffline) return true;

    const isInProject = !!projectId;
    if (!isInProject) return false;

    if (this.offlineService.isProjectOffline(projectId)) return true;

    return this.isActiveModuleOfflineCapable && useOfflineDataWhenSynced
      ? await this.offlineService.isAutoSyncEnabled(projectId)
      : false;
  }

  private async tryOfflineAsync<T>(
    onlineCall: () => Promise<T>,
    offlineCall: () => Promise<T>,
    projectId: string = null,
    hasOfflineData = true
  ): Promise<T> {
    const useOfflineData = await this.useOfflineData(projectId, hasOfflineData);

    return useOfflineData
      ? await offlineCall()
      : await onlineCall().catch(async e => await this.handleOfflineErrorAsync(e, offlineCall));
  }

  private async handleOfflineErrorAsync<T>(error: any, offlineFallback: () => Promise<T>) {
    if (CapacitorUtils.isApp() && error.status == 0) {
      try {
        return await offlineFallback();
      } catch {
        // throw original error to not interfere with user addressed handling if offline is not supported
        throw error;
      }
    }

    throw error;
  }

  private tryOffline<T>(
    onlineRequest: Observable<T>,
    offlineCall: () => Promise<T>,
    projectId: string = null,
    hasOfflineData = true
  ): Observable<T> {
    const offlineRequest = defer(() => from(offlineCall()));
    const useOfflineData$ = defer(() => from(this.useOfflineData(projectId, hasOfflineData)));

    return useOfflineData$.pipe(
      switchMap(useOfflineData =>
        useOfflineData ? offlineRequest : onlineRequest.pipe(this.handleOfflineError(offlineRequest))
      )
    );
  }

  private handleOfflineError<T, O>(offlineFallback: Observable<O>) {
    return catchError<T, Observable<O>>(e => {
      if (CapacitorUtils.isApp() && e.status == 0) {
        return offlineFallback.pipe(
          catchError(() => {
            // throw original error to not interfere with user addressed handling if offline is not supported
            throw e;
          })
        );
      }

      throw e;
    });
  }

  private normalizeDrivePath(path: string): string {
    if (Utils.isNullOrWhitespace(path)) {
      return '';
    }
    if (path.startsWith('/')) {
      return path.substr(1);
    }
    return path;
  }

  private generateProjectKey(base: ProjectCacheKey, projectId: string, ...optionalIdentifiers: string[]) {
    let baseKey = `${base}:${projectId}`;

    for (const identifier of optionalIdentifiers) {
      baseKey += `:${identifier}`;
    }

    return baseKey;
  }

  private generateViewKey(type: PropertyBagType, key: string = null, projectId: string = null) {
    let cacheKey = `${AppCacheKey.view}:${type}`;
    if (!!key?.trim()) cacheKey += `:${key}`;
    if (!!projectId?.trim()) cacheKey += `:${projectId}`;
    return cacheKey;
  }

  private linkCategoryParents(categories: ExtendedCategoryModel[], parent: ExtendedCategoryModel = null) {
    for (const category of categories) {
      if (parent) category.parent = parent;
      if (category.children?.length) this.linkCategoryParents(category.children, category);
    }
  }

  private encodeResource(resource: string) {
    if (resource) return encodeURIComponent(resource);
    else return resource;
  }

  // #endregion
}
