import {
  Component,
  OnInit,
  AfterViewChecked,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  NgZone,
  HostListener,
} from '@angular/core';
import {
  GlobalsService,
  AuthenticationService,
  TranslationManagementService,
  ResponsivenessService,
  AppConfigService,
  DragDropItem,
  Teams,
  ApiAuthenticationService,
  GlobalFormStateTrackerService,
  ApiService,
  BaseSubscriptionComponent,
  ProjectService,
  InAppBrowserNavigationClient,
  OfflineService,
} from './core';
import * as microsoftTeams from '@microsoft/teams-js';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TeamsUrlParams } from './C4CustomerPortal/msteams/msteams-enums';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CustomizationService } from './shared/services/customization/customization.service';
import { CapacitorUtils } from './core/utils/capacitor-utils';
import { environment } from '@env/environment';
import { Moment } from 'moment';
import * as moment from 'moment';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { TextZoom } from '@capacitor/text-zoom';
import { NotificationModel, NotificationSendType } from './api';
import { NotifyAfterLoginDialogComponent } from './shared/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { SideBarService } from './core/services/globals/side-bar.service';
import { DateAdapter } from '@angular/material/core';
import { LocaleHelper, LocaleManager } from '@bryntum/schedulerpro';
import BryntumLocaleEn from 'assets/languages/bryntum-scheduler/en-US';
import BryntumLocaleDe from 'assets/languages/bryntum-scheduler/de-DE';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import { MsalService } from '@azure/msal-angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends BaseSubscriptionComponent
  implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit, OnDestroy, DragDropItem
{
  teamsCfg: Teams;
  isAuthenticated = false;
  private scrolledClass = 'scrolled';
  mobileClass = 'ismobile';
  mobileMenuClass = 'ismobileMenu';
  phoneClass = 'isphone';
  initScrollListener = true;
  isDragDrop = false;
  siteLang = 'default';
  appResumed: boolean = false;

  @HostListener('window:beforeunload')
  beforeUnload() {
    return !this.globalFormStateTracker.isDirty();
  }
  private navRegex: RegExp = new RegExp(/(?<=\/)([a-zA-z]+?)(?=\b|\/)/g); // regex to get the first part of the url

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    inAppBrowser: InAppBrowser,
    private apiAuthenticationService: ApiAuthenticationService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private ccService: NgcCookieConsentService,
    private dateAdapter: DateAdapter<Moment>,
    private dialog: MatDialog,
    private globalFormStateTracker: GlobalFormStateTrackerService,
    private msalAuthService: MsalService,
    private offlineService: OfflineService,
    private projectService: ProjectService,
    private responsivenessService: ResponsivenessService,
    private router: Router,
    private sideBar: SideBarService,
    private titleService: Title,
    private translateService: TranslateService,
    private translationService: TranslationManagementService,
    private zone: NgZone,
    public customization: CustomizationService,
    public globals: GlobalsService
  ) {
    super();
    this.translationService.init(AppConfigService.settings.language);
    this.responsivenessService.init(changeDetectorRef);
    this.teamsCfg = AppConfigService.settings.teams;
    this.msalAuthService.instance.setNavigationClient(new InAppBrowserNavigationClient(inAppBrowser, apiAuthenticationService));
  }

  async ngOnInit() {
    this.initializeApp();
    this.initMoment();
    this.initBryntum();
    this.checkUrlParams();
    this.initAuthServiceFunctions();
    this.initMicrosoftTeams();
    this.initSentry();

    this.projectService.refreshTenant();
    await this.updateIsAuthenticated();
    this.subscribe(this.authenticationService.loginStateChanged, async _ => {
      await this.updateIsAuthenticated();
    });

    this.initMobileQueryListener();
    const appBody = document.getElementById('APP_Body');

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routerevent: NavigationEnd) => {
      //const attribute = routerevent.url.split('?')[0].replace(/[^a-zA-Z ]/g, ''); //--> changed to windowlocation pathname -> avoid reroute missing attribute errors
      const attribute = window.location.pathname.split('?')[0].match(this.navRegex).join('-');
      appBody.setAttribute('page', attribute);
    });

    if (!this.globals.isTeams && !CapacitorUtils.isApp() && environment.production) {
      this.initCookieConsent();
    }

    this.customization.setTheme();

    this.globals.registerDragDropItem(this);
    document.addEventListener('dragover', (ev: DragEvent) => {
      this.onDragOver(ev);
    });
    document.addEventListener('dragend', (ev: DragEvent) => {
      this.onDragEnd(ev);
    });
    document.addEventListener('dragenter', (ev: DragEvent) => {
      this.onDragEnter(ev);
    });
    document.addEventListener('dragleave', (ev: DragEvent) => {
      this.onDragLeave(ev);
    });
    document.addEventListener('drop', (ev: DragEvent) => {
      this.onDrop(ev);
    });

    this.subscribe(this.projectService.projectId$, async projectId => {
      if (projectId) {
        const project = await this.apiService.getProject(projectId);
        this.titleService.setTitle(project.name);
        await this.showNotificationsIfAny();
      } else {
        this.titleService.setTitle(AppConfigService.settings.title);
      }
    });
  }

  private initSentry() {
    if (environment.sentryUrl) {
      Sentry.init(
        {
          dsn: environment.sentryUrl,
          stackParser: SentryAngular.defaultStackParser,
          environment: environment.sentryEnvironment,
          integrations: [
            Sentry.browserTracingIntegration(),
            SentryAngular.captureConsoleIntegration({
              levels: ['error', 'warn'],
            }),
          ],
          replaysOnErrorSampleRate: AppConfigService.settings.sentry.replaysOnErrorSampleRate,
          replaysSessionSampleRate: AppConfigService.settings.sentry.replaysSessionSampleRate,
          tracesSampleRate: AppConfigService.settings.sentry.tracesSampleRate,
        },
        SentryAngular.init
      );
    }
  }

  ngOnDestroy() {
    this.globals.unregisterDragDropItem(this);
    return super.ngOnDestroy();
  }

  initializeApp() {
    if (!CapacitorUtils.isApp()) return;

    TextZoom.set({ value: 1 });

    App.addListener('resume', async () => {
      if (await this.authenticationService.isAuthenticated()) {
        this.appResumed = true;
        this.offlineService.syncAutoSyncedProjects();
      }
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = new URL(event.url);

        if (url.host.endsWith(AppConfigService.settings.publicDomain)) {
          const slug = url.href.substring(url.origin.length); // '/module/view?arg=1#anchor'

          if (slug) {
            if (this.appResumed) {
              this.router.navigateByUrl(slug);
              this.appResumed = false;
            } else this.apiService.setAppLinkSlugCookie(slug);
          }
        }
      });
    });
  }

  initMoment() {
    window.moment = moment; // for testing

    this.translationService.onLangChange.subscribe(options => {
      moment.locale(options.lang.substring(0, 2));
      this.dateAdapter.setLocale(options.lang);
    });

    // don't f... with it: https://momentjs.com/docs/#/customization/dow-doy/
    moment.updateLocale('de', {
      // EUROPE
      week: {
        dow: 1,
        doy: 4,
      },
    });

    moment.updateLocale('en', {
      // EUROPE
      week: {
        dow: 1,
        doy: 4,
      },
    });
  }

  private initBryntum() {
    LocaleHelper.publishLocale('en-US', BryntumLocaleEn);
    LocaleHelper.publishLocale('de-DE', BryntumLocaleDe);

    LocaleManager.applyLocale(this.translationService.language);

    this.translationService.onLangChange.subscribe(options => {
      LocaleManager.applyLocale(options.lang);
    });
  }

  private initAuthServiceFunctions() {
    this.authenticationService.setRefreshFunction(async (oldToken: string, refreshToken: string) => {
      return await this.apiAuthenticationService.refreshToken(oldToken, refreshToken);
    });

    this.authenticationService.setLogoutFunction(async (refreshToken: string) => {
      return await this.apiAuthenticationService.logout(refreshToken);
    });
  }

  private async initMicrosoftTeams() {
    const appBody = document.getElementById('APP_Body');

    try {
      await microsoftTeams.app.initialize();
    } catch (error) {
      // Ignore error as this is most likely the result of not running in teams
    }

    if (microsoftTeams.app.isInitialized() === false) return;

    let context = await microsoftTeams.app.getContext();

    if (!context) return;

    appBody.setAttribute('teams', 'true');

    this.globals.isTeams = true;
    this.globals.currentTeamsGroupId = context.team.groupId;
    this.globals.currentTeamsUserId = context.user.id;
    this.globals.currentTeamsTenantId = context.user.tenant.id;

    let config = await microsoftTeams.pages.getConfig();

    if (config) this.globals.currentTeamsContentUrl = config.contentUrl;
  }

  private checkUrlParams() {
    const paramString = window.location.search;
    const isTeams = paramString.indexOf(TeamsUrlParams.IsTeamsAuth) >= 0;
    const isContentOnly = paramString.indexOf(TeamsUrlParams.IsContentOnly) >= 0;
    this.globals.isOAuth = paramString.indexOf('OAuth') >= 0;

    const appBody = document.getElementById('APP_Body');
    appBody.setAttribute(TeamsUrlParams.TeamsAuth, isTeams.toString());
    appBody.setAttribute(TeamsUrlParams.ContentOnly, isContentOnly.toString());
    this.globals.isTeams = isTeams;
  }

  private initMobileQueryListener() {
    this.responsivenessService.registerQuery('(max-width: 1444px)', (matches: boolean) => {
      this.globals.isMobile = matches;
      if (matches) {
        document.body.classList.add(this.mobileClass);
        this.sideBar.minify(true);
      } else {
        document.body.classList.remove(this.mobileClass);
        this.sideBar.enlarge(true);
        this.sideBar.open();
      }
    });
    this.responsivenessService.registerQuery('(max-width: 959px)', (matches: boolean) => {
      this.globals.isMobileMenu = matches;
      if (matches) {
        document.body.classList.add(this.mobileMenuClass);
        this.sideBar.enlarge(true);
      } else {
        document.body.classList.remove(this.mobileMenuClass);
        this.sideBar.minify(true);
        this.sideBar.open();
      }
    });
    this.responsivenessService.registerQuery('(max-width: 599px)', (matches: boolean) => {
      this.sideBar.enlarge(true);
      this.globals.isPhone = matches;
      if (matches) {
        document.body.classList.add(this.phoneClass);
      } else {
        document.body.classList.remove(this.phoneClass);
      }
    });
  }

  private initFrill() {
    let frillConfig: FrillConfig = {
      ...AppConfigService.settings.frill,
      callbacks: {
        onReady: frillWidget => {
          this.globals.frillWidget = frillWidget;
        },
      },
    };

    window.Frill_Config = window.Frill_Config || [];
    window.Frill_Config.push(frillConfig);
    if ('Frill' in window) {
      window.Frill.widget(frillConfig);
    }
  }

  private initCookieConsent() {
    this.subscribe(this.translateService.onLangChange, (event: any) => {
      this.setCookieConsent();
    });

    this.setCookieConsent();
  }

  private async setCookieConsent() {
    const message = await this.translateService.get('custom.cookies.message').toPromise();
    const config = AppConfigService.settings.cookieConsentConfig;

    this.ccService.destroy();
    if (config.cookie.domain && config?.content) {
      config.content.message = message;
      this.ccService.init(config);
    }
  }

  ngAfterViewChecked(): void {
    if (this.isAuthenticated) {
      const that = this;
      if (this.initScrollListener) {
        this.updateScrollState();
        window.addEventListener('scroll', function () {
          that.updateScrollState();
        });
        this.initScrollListener = false;
      }
    } else {
      this.initScrollListener = true;
    }
  }

  private async updateIsAuthenticated() {
    this.isAuthenticated = await this.authenticationService.isAuthenticated();
  }

  updateScrollState() {
    if (window.pageYOffset < 32) {
      document.body.classList.remove(this.scrolledClass);
    } else {
      document.body.classList.add(this.scrolledClass);
    }
  }

  ngAfterViewInit() {
    this.initFrill();
  }

  onDrop(event) {
    this.disableDragDrop(event, false);
  }

  onDragLeave(event) {
    this.disableDragDrop(event, false);
  }

  onDragEnd(event) {
    this.disableDragDrop(event, false);
  }

  onDragOver(event) {
    this.disableDragDrop(event, true);
  }

  onDragEnter(event) {
    this.disableDragDrop(event, true);
  }

  private disableDragDrop(event, isDragDrop: boolean) {
    // fix to prevent dropEffect None on p-treenode-droppoint items
    if (event.srcElement.classList.contains('p-treenode-droppoint')) return;

    event.stopPropagation();
    event.preventDefault();
    this.isDragDrop = isDragDrop;
    if (isDragDrop && event.dataTransfer) {
      event.dataTransfer.dropEffect = 'none';
      event.dataTransfer.effectAllowed = 'none';
    }
  }

  private async showNotificationsIfAny() {
    const afterLoginNotifications = await this.apiService
      .getNotifications(true, true, NotificationSendType.Input)
      .catch(() => [] as NotificationModel[]);
    if (afterLoginNotifications.length > 0) {
      for (const afterLoginNotification of afterLoginNotifications) {
        await this.dialog
          .open(NotifyAfterLoginDialogComponent, {
            data: { notification: afterLoginNotification },
          })
          .afterClosed()
          .toPromise();
      }
    }
  }
}
