import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormComponent } from '@app/core/utils/form-component';
import { FormBuilder, Validators } from '@angular/forms';
import { DriveItemModel } from '@app/api';
import { FileSharingService, UserNotificationService } from '@app/shared/services';
import { ApiService, AppConfigService, CustomValidators, LogService } from '@app/core';
import * as moment from 'moment';

@Component({
  selector: 'app-file-sharing-details',
  templateUrl: './file-sharing-details.component.html',
  styleUrls: ['./file-sharing-details.component.scss'],
})
export class FileSharingDetailsComponent extends FormComponent implements OnInit {
  @Output() onAddItem = new EventEmitter<void>();

  driveItems: DriveItemModel[] = [];

  constructor(
    private apiService: ApiService,
    private fileSharingService: FileSharingService,
    private formBuilder: FormBuilder
  ) {
    super();

    this.form = this.formBuilder.group({
      //name: ['', [Validators.required]], currently not used
      validUntil: [null, [Validators.required, CustomValidators.afterDate(moment().add(-1, 'days').endOf('day'))]],
    });
  }

  async ngOnInit() {
    let validUntil = moment().add(4, 'weeks');
    try {
      const tenantSettings = await this.apiService.getTenantSettings();
      validUntil = moment().add(tenantSettings.signedUrlExpirationInMinutes, 'minutes');
    } catch (e) {
      // consider to log a warning
    }

    this.subscribe(this.fileSharingService.sharedDriveItems$, async driveItems => {
      this.driveItems = driveItems ?? [];
      if (this.driveItems.length == 0) this.form.reset({ validUntil });
    });
  }

  removeDriveItem(driveItem: DriveItemModel) {
    this.fileSharingService.removeDriveItem(driveItem);
  }

  async sendMail() {
    await this.fileSharingService.shareItemsByEmail(this.form.value);
  }

  async createLink() {
    await this.fileSharingService.shareItemsByLink(this.form.value);
  }

  async clearFileSharing() {
    await this.fileSharingService.removeAllDriveItems(true);
  }
}
