import { Component, OnInit } from '@angular/core';
import {
  GlobalsService,
  AuthenticationService,
  ApiService,
  Utils,
  TranslationManagementService,
  TopBarRenderType,
  AppConfigService,
  TopUserRenderType,
  BaseSubscriptionComponent,
  ProjectService,
  GlobalFormStateTrackerService,
  OfflineService,
  pathFragmentsToAdmin,
  AppRoutingData,
  pathTo,
  pathFragmentsTo,
  guidRegex,
} from '@app/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from '@env/environment';
import { OkDialogComponent } from '../../dialogs/ok-dialog/ok-dialog.component';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { UserProfileComponent } from '../../userprofile/userprofile.component';
import { PrivilegeEnum, ProjectModel, TenantInfoModel, UserSessionModel } from '@app/api';
import { ChangePasswordComponent } from '../../change-password/change-password.component';
import { UserNotificationService } from '@app/shared/services';
import { ChangeProjectComponent } from '../../change-project/change-project.component';
import { NotificationSettingsComponent } from '../../notification-settings/notification-settings.component';
import { CustomizationService } from '@app/shared/services/customization/customization.service';
import { UserSettingsDialogComponent } from '../../dialogs/user-settings-dialog/user-settings-dialog.component';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { SideBarService } from '@app/core/services/globals/side-bar.service';
import { CopyInfoDialogComponent } from '../../dialogs/copy-info-dialog/copy-info-dialog.component';
import { Crumb } from '../../breadcrumb/breadcrumb.component';
import { filter } from 'rxjs/operators';
import { ZohoService } from '@app/core/services/globals/zoho.service';
import { ScanQrDialogComponent } from '../../dialogs/scan-qr-dialog/scan-qr-dialog.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent extends BaseSubscriptionComponent implements OnInit {
  appName: string;
  appVersion: string;
  user: UserSessionModel;
  username: string;
  companyName: string = null;
  canChangePassword: boolean;
  languages: string[];
  logoUrl: string;
  topLogo: TopBarRenderType = TopBarRenderType.image;
  topUserLogo: TopUserRenderType = TopUserRenderType.default;
  render = TopBarRenderType;
  userRender = TopUserRenderType;
  isInAdminPortal = false;
  isInProject = false;
  isAdmin: boolean;
  isImpersonating = false;
  urlSegments: UrlSegment[];
  crumbs: Crumb[] = [];
  isCapacitor: boolean;
  isDebugMode: boolean = environment.production == false;
  projectsPathFragments: string[] = [];

  private tenant: TenantInfoModel;
  private currentProject: ProjectModel;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private sideBar: SideBarService,
    private globalFormStateTracker: GlobalFormStateTrackerService,
    private userNotification: UserNotificationService,
    private offlineService: OfflineService,
    public authService: AuthenticationService,
    public customization: CustomizationService,
    public globals: GlobalsService,
    public translate: TranslationManagementService,
    public zoho: ZohoService
  ) {
    super();

    this.appName = environment.name;
    this.appVersion = environment.version;

    this.languages = translate.supportedLanguages;

    this.isCapacitor = CapacitorUtils.isApp();
  }

  get isAppOffline$() {
    return this.offlineService.isOffline$;
  }

  async ngOnInit() {
    this.projectsPathFragments = pathFragmentsTo();

    this.subscribe(this.projectService.tenant$, tenant => {
      this.tenant = tenant;
      this.buildBreadCrumbs();
    });

    this.subscribe(this.projectService.projectId$, async projectId => {
      this.isInAdminPortal = this.router.url.startsWith('/admin');
      this.isInProject = projectId != null;
      this.currentProject = projectId ? await this.apiService.getProject(projectId) : null;
      this.buildBreadCrumbs();
    });

    this.subscribe(this.apiService.userSettingsUpdated, async () => {
      await this.loadUser();
    });

    this.subscribe(this.router.events.pipe(filter(event => event instanceof NavigationEnd)), event => {
      this.buildBreadCrumbs();
    });

    const privileges = await this.apiService.getUserPrivileges();
    this.isAdmin = privileges.includes(PrivilegeEnum.Admin);

    this.logoUrl = AppConfigService.settings.api.url + '/tenant/projectlogo';
    this.topLogo = AppConfigService.settings.topHeader ? AppConfigService.settings.topHeader : TopBarRenderType.image;
    this.topUserLogo = AppConfigService.settings.topUserHeader
      ? AppConfigService.settings.topUserHeader
      : TopUserRenderType.default;
    await this.loadUser();
    try {
      this.canChangePassword = (await this.apiService.getUserSession()).canChangePassword;
    } catch (e) {
      await this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.loginConfig', e);
    }
  }

  async scanQr() {
    try {
      const scan = await this.dialog.open(ScanQrDialogComponent).afterClosed().toPromise();

      if (scan) {
        const link = new URL(scan);

        if (link && link.host.endsWith(AppConfigService.settings.publicDomain)) {
          const slug = link.href.substring(link.origin.length); // '/module/view?arg=1#anchor'

          if (slug) {
            this.router.navigateByUrl(slug);
          }
        } else {
          throw 'Invalid Origin';
        }
      }
    } catch (e) {
      this.userNotification.notify('general.errorMsg.scanQr', { error: e });
    }
  }

  toggleZoho() {
    this.zoho.open();
  }

  toggleFrill() {
    if (this.globals.frillWidget) {
      this.globals.frillWidget.toggle();
    }
  }

  toggleSidebar() {
    this.sideBar.toggleOpenState();
  }

  async openUserSettings() {
    await this.dialog.open(UserSettingsDialogComponent).afterClosed().toPromise();
  }

  userLogout() {
    this.router.navigate(['logout']);
  }

  about() {
    const params = {
      appVersion: this.appVersion,
      appName: this.appName,
    };
    this.dialog.open(OkDialogComponent, {
      data: { title: 'dialogs.about.caption', description: 'dialogs.about.description', params },
    });
  }

  changeLang(lang: string) {
    this.translate.language = lang;
  }

  changePassword() {
    this.dialog.open(ChangePasswordComponent);
  }

  editUserProfile() {
    this.dialog.open(UserProfileComponent, {
      // panelClass: 'shrink',
    });
  }

  editNotificationSettings() {
    this.dialog.open(NotificationSettingsComponent);
  }

  helpCenter() {
    this.router.navigateByUrl('/help/index');
  }

  async copyAccessToken() {
    await navigator.clipboard.writeText(`Bearer ${await this.authService.getCurrentAccessToken()}`);

    this.userNotification.notify('AccessToken copied to clipboard');
  }

  get useDebugOffline() {
    return this.offlineService.useDebugOffline;
  }

  get isDebugOffline$() {
    return this.offlineService.isDebugOffline$;
  }

  toggleDebugOffline() {
    this.offlineService.toggleDebugOffline();
  }

  async dumpSQLiteStorage() {
    const data = await this.offlineService.recovery(this.currentProject.id, false, true);
    if (!data) return;

    const proceed = await this.dialog
      .open(CopyInfoDialogComponent, {
        data: {
          title: 'offline.dialogs.recovery.caption',
          description: 'offline.dialogs.recovery.description',
          copyableString: data,
        },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();
  }

  async changeProject() {
    await this.dialog.open(ChangeProjectComponent).afterClosed().toPromise();
  }

  async navigateToAdminPortal() {
    if (await this.globalFormStateTracker.canLeave()) {
      await this.router.navigate(pathFragmentsToAdmin());
    }
  }

  private async loadUser() {
    try {
      this.user = await this.apiService.getUserSession();
    } catch (e) {
      await this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.userData', e);
      this.user = new UserSessionModel({
        username: '',
        firstname: '',
        lastname: '',
        emailAddress: '',
      });
    }
    if (Utils.isNullOrWhitespace(this.user.firstname) || Utils.isNullOrWhitespace(this.user.lastname)) {
      this.username = this.user.username;
    } else {
      this.username = `${this.user.firstname} ${this.user.lastname}`;
    }
  }

  private buildBreadCrumbs() {
    this.crumbs = [];

    //remove URL parameters (?); remove URL fragment (#); decode url (e.g. %20 => whitespaces)
    const parts = this.router.url
      .split('?')[0]
      .split('#')[0]
      .split('/')
      .map(p => decodeURIComponent(p));

    // remove project id
    let projectIndex = parts.indexOf('projects');
    let projectId: string = null;
    if (projectIndex > 0 && parts[projectIndex + 1]?.match(guidRegex)) {
      if (this.currentProject) {
        parts.splice(projectIndex, 2);
      } else {
        [projectId] = parts.splice(projectIndex + 1, 1);
      }
    }

    // remove template id
    let templatesIndex = parts.indexOf('templates');
    let templateId: string = null;
    if (templatesIndex > 0 && parts[templatesIndex + 1]?.match(guidRegex)) {
      [templateId] = parts.splice(templatesIndex + 1, 1);
    }

    if (this.tenant) {
      this.crumbs.push({ label: this.tenant.name, routerLink: pathTo() });
    }

    if (this.currentProject) {
      this.crumbs.push({
        label: this.currentProject.name,
        routerLink: pathTo(this.currentProject.id, AppRoutingData.dashboard.path),
      });
    }

    let currentPath = '/';
    parts.forEach(part => {
      let nextCrumb: Crumb = { label: '', routerLink: '' };
      //--------------------------- import from manager ---------------------------
      //get rid of:
      //-) get rid off all numbersonly/ids in bradcrumbs ;)
      if (~~part > 0) {
        const lastIndex = this.crumbs.length - 1;
        if (lastIndex >= 0) {
          this.crumbs[lastIndex].routerLink += '/' + part;
        }
        return;
      }
      //--------------------------- import from manager ---------------------------

      if (!part) {
        // if (this.useHomePath) {
        //   nextCrumb.label = this.homePath + '.title';
        //   nextCrumb.path = `/${this.homePath}/`;
        // } else {
        // }
        nextCrumb = null;
      } else {
        currentPath += part + '/';
        const documents = `/${AppRoutingData.documents.path}/`;
        const index = currentPath.indexOf(documents);
        const isDocumentsSubRoute = index >= 0 && index + documents.length < currentPath.length;
        const path = isDocumentsSubRoute ? `/${part}` : currentPath.replace(/\//g, '.') + 'title';
        nextCrumb.label = path.substr(1, path.length);
        if ((projectId || templateId) && currentPath.indexOf('edit') > 0) {
          // fix route for navigation but keep translation
          const split = currentPath.split('/');
          split.splice(split.indexOf('edit'), 0, templateId ?? projectId);
          nextCrumb.routerLink = split.join('/');
        } else {
          nextCrumb.routerLink = currentPath;
        }
      }
      if (nextCrumb) this.crumbs.push(nextCrumb);
    });

    if (this.crumbs.length == 1) this.crumbs = [];
  }
}
